import React from "react";
import { Button, Card, Form, FormGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReportTable from "../components/Table";
import AddUpdateModal from "../feature/AttendeeMangement/Staffmodal";
import { useDispatch } from "react-redux";

import {
  attendeeDeptCollege,
  attendeeAddStaff,
  attendeeUpdateStaff,
  attendeeDesignationMaster,
  attendeeSingleStaff,
  attendeeAddUpdateDesignation,
  attendeeUpdateGeo,
  attendeeStaffList,
  attendeeUpdateStatus,
  attendeeRoleList
} from "../Store/SagaActions/AttendeeMangementSagaActions"

import { monthlyDropdownDetailsAction } from "../Store/SagaActions/TableDetailSagaAction";
import AreUSureModal from "../components/Modals/Duplicates";
import { toast } from "react-hot-toast";

function AttendeeStaff() {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false); // show modal
  const [warningPopup, setWarningPopup] = useState(false); //are u sure pop up

  const [deptDropDown, setDeptDropDown] = useState([])
  const [orgDropDown, setOrgDropDown] = useState([])
  const [designationDropDown, setDesignationDropDown] = useState([])
  const [roleDropDown, setRoleDropDown] = useState([])


  const [SelectedDept, setSelectedDept] = useState("0")
  const [SelectedOrg, setSelectedOrg] = useState("0")
  const [SelectedDesignation, setSelectedDesignation] = useState("0")
  const [SelectedRole, setSelectedRole] = useState("0")
  const [StaffTableData, setStaffTableData] = useState([])
  const [isEdit, setisEdit] = useState(false)


  const [rowData, setRowData] = useState({})
  const [staffFullTableData, setstaffFullTableData] = useState([])
  const [infoMessage, setInfoMessage] = useState("")
  const [remarkDeatils, setRemarkDeatils] = useState(undefined)
  const [CallType, setCallType] = useState("")
  const [gender, setGender] = useState("")
  const [nodalOfficer, setNodalOfficer] = useState("")
  const [singleInfoData, setSingleInfoData] = useState({})
  const [FormDeatils, setFormDeatils] = useState({})
  const [validated, setValidated] = useState(false); //for form validation
  const [addLabel, setaddLabel] = useState("")
  const [selectedRow,setSelectedRow]=useState("0")
  const[dataChange,setDataChange]=useState(false)



  const toggleSwitch = (toggle) => {
    return toggle === "1" ?
      <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.182" y="2.182" width="25.636" height="13.156" rx="6.578" stroke="#38CAB3" stroke-width="0.364" />
        <g filter="url(#filter0_d_150_24)">
          <circle cx="19.24" cy="8.75999" r="6.24" fill="#38CAB3" />
        </g>
        <defs>
          <filter id="filter0_d_150_24" x="10" y="0.519989" width="18.48" height="18.48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_24" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_24" result="shape" />
          </filter>
        </defs>
      </svg> :
      <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.182" y="2.182" width="25.636" height="13.156" rx="6.578" stroke="#B7B7B7" stroke-width="0.364" />
        <g filter="url(#filter0_d_150_15)">
          <circle cx="9.76002" cy="8.75999" r="6.24" fill="#B7B7B7" />
        </g>
        <defs>
          <filter id="filter0_d_150_15" x="0.52002" y="0.519989" width="18.48" height="18.48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_15" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_15" result="shape" />
          </filter>
        </defs>
      </svg>


  }

  function deptDropdown() {
    dispatch(
      monthlyDropdownDetailsAction({
        callback: (data) => {
          departmentDowndata(data)
        }
      })
    )
  };

  function GetOrgOptions(deptid) {
    let model = {
      "deptid": deptid
    }
    dispatch(
      attendeeDeptCollege({
        model,
        callback: (data) => {
          orgnisationdowndata(data)
        }
      })
    )
  }

  const GetdesignationOptions = (collegeorgid) => {
    let model = {
      "collegeorgid": collegeorgid
    }
    dispatch(
      attendeeDesignationMaster({
        model,
        callback: (data) => {
          designationData(data)
        }
      })
    )
    dispatch(
      attendeeRoleList({
        callback: (data) => {
          roleData(data)
        }
      })
    )
  }


  const departmentDowndata = (data) => {
    setStaffTableData([])
    setDeptDropDown(data)
  }
  const orgnisationdowndata = (data) => {
    setStaffTableData([])
    setOrgDropDown(data?.departmentwisecollegeslistdetails)

  }
  const designationData = (data) => {
    setDesignationDropDown(data)
    setSelectedDesignation(`${data[0]?.desmid}`)
  }
  const roleData = (data) => {
    setRoleDropDown(data)
    setSelectedRole(`${data[0]?.roleid}`)
  }

  const OnSelectDept = (e) => {
    const { value } = e?.target
    const selectedIndex = e.target.selectedIndex 
    const label = e.target[selectedIndex].label
    setaddLabel(label)

    setSelectedDept(value)
    setSelectedOrg("0")
    setOrgDropDown([])
    GetOrgOptions(value)
  }
  const OnSelectOrg = (e) => {
    const { value } = e?.target
    setSelectedOrg(value)
    GetdesignationOptions(value)
  }
  const OnSelectDesignation = (e) => {
    const { value } = e?.target
    setSelectedDesignation(value)
  }
  const OnSelectRole = (e) => {
    const { value } = e?.target
    setSelectedRole(value)
  }

  useEffect(() => {
    deptDropdown();
  }, []);

  const getTableData = () => {
    if (SelectedOrg !== "0") {
      let model = {
        "collegeorgid": SelectedOrg
      }
      dispatch(
        attendeeStaffList({
          model,
          callback: (data) => {
            StaffList(data)
          }
        })
      )
    }
  }

  const StaffList = (data) => {
    setstaffFullTableData(data?.stafflist)
    if (data?.stafflist.length === 0) toast.error("no data found")
    if (!(data?.stafflist)) toast.error("Something unexpected happened")

    const datamapped = (data?.stafflist).map((i, idx) => {
      const { attdcode, email, gender, latitude, longitude, mobile, attdname, attendeeStatus, attendeegeofencingStatus,geofencing_remarks } = i
      const temp = {
        "Name": attdname === "" ? "_" : attdname,
        "Mobile Number": mobile === "" ? "_" : mobile,
        "Email ID": email === "" ? "_" : email,
        "Staff code": attdcode === "" ? "_" : attdcode,
        "Gender": gender === "" ? "_" : gender,
        "Latitude": latitude === "" ? "_" : latitude,
        "longitude": longitude === "" ? "_" : longitude,
        "Comment":geofencing_remarks===""?"_":geofencing_remarks,
        "Geo Location": toggleSwitch(attendeegeofencingStatus),
        "Profile": toggleSwitch(attendeeStatus),
        "Edit": <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.607 0.318308C14.4031 0.114495 14.1266 0 13.8383 0C13.55 0 13.2735 0.114495 13.0696 0.318308L11.5323 1.85665L16.1443 6.46841L17.6817 4.93116C17.8855 4.72729 18 4.45081 18 4.16253C18 3.87425 17.8855 3.59778 17.6817 3.3939L14.607 0.318308ZM14.607 8.00675L9.99385 3.3939L0.317472 13.0697C0.114227 13.2734 6.11438e-05 13.5495 0 13.8372V16.9128C0 17.2012 0.114548 17.4777 0.318443 17.6816C0.522339 17.8855 0.798881 18 1.08723 18H4.16193C4.45026 17.9999 4.72675 17.8854 4.9306 17.6815L14.607 8.00567V8.00675Z" fill="#38CAB3" />
        </svg>,
      }
      return temp;
    });
    setStaffTableData(datamapped)
  }

  useEffect(() => {
    getTableData()
  }, [SelectedOrg]);

  const openModal = (e) => {
    setRowData({})
    setValidated(false)
    setisEdit(false)
    setSingleInfoData({})
    if (isEdit === false){ 
      setGender("")
      setNodalOfficer("")
    }
    setCallType(e)
    setShowPopup(true)
  }

  const gotoedit = () => {
    setisEdit(true)
    setSingleInfoData({})
    setCallType("edit")
  }
  const getModel = (FormDetails, _aid, _columnname) => {
    const calltype = _columnname === undefined ? CallType : _columnname
    switch (calltype) {
      case "add":
        return {
          "deptid": SelectedDept,
          "collegeorgid": SelectedOrg,
          "gender": gender,
          "designation": SelectedDesignation,
          // "role": SelectedRole,
          "isnodal": nodalOfficer,
          "geostatus": infoMessage.includes("Disable") ? "0" : "1",
          "roleid":SelectedRole,
          ...rowData,
        }
      case "edit":
        return {
          "aid": staffFullTableData[selectedRow]?.aid,
          "attendeestatus": rowData?.attendeeStatus,
          "gender": gender,
          "isnodal": nodalOfficer,
          "geostatus": rowData?.attendeegeofencingStatus,
          "mobile": rowData?.mobile,
          "email": rowData?.email,
          "latitude": rowData?.latitude,
          "longitude": rowData?.longitude,
          "attdname": rowData?.attdname,
          "roleid":SelectedRole,
          ...rowData,
        }
      case "profile":
        return {
          "aid": staffFullTableData[selectedRow]?.aid,
          "remarks": remarkDeatils,
          "attdstatus": rowData?.attendeeStatus === "0" ? "1" : "0",
        }
      case "geo location":
        return {
          "aid": staffFullTableData[selectedRow]?.aid,
          "status": rowData?.attendeegeofencingStatus === "0" ? "1" : "0",
          "remarks": remarkDeatils,
          // "latitude": rowData?.latitude,
          // "longitude": rowData?.longitude,
          "type": "1",
        }
      case "name":
      case "mobile number":
        return {
          "aid": _aid,
        }
      default:
        return {}
    }

  }
  const addUpdateStaff = (e, FormDetails, _aid, _columnname) => {
    e.preventDefault()
    const form = e.currentTarget;
    let model = getModel(FormDetails, _aid, _columnname)
    const calltype = _columnname === undefined ? CallType : _columnname
    switch (calltype) {
      case "add":
        if(form.checkValidity()){
          if(gender!=="" && nodalOfficer!==""){
            dispatch(
              attendeeAddStaff({
                model, callback: (data) => {
                  if (data?.responsecode === "200") {
                    setFormDeatils({})
                    addStaff(data)
                  }
                  else toast.error(data?.responsedesc + " Please try again")
                }
              })
            )
          }
          else{
            if(nodalOfficer===""){
              toast.error(" Please select IsNodal")
            }
            if(gender==="" ){
              toast.error(" Please select gender")
            }
          }
        }
        return
      case "edit":
          if (form.checkValidity() && dataChange) {
            if(model.remarks!==undefined){
              dispatch(
                attendeeUpdateStaff({
                  model, callback: (data) => {
                    if (data?.responsecode === "200") {
                      setFormDeatils({})
                      setRowData({})
                      addStaff(data)
                    }
                    else toast.error(data?.responsedesc + " Please try again")
                  }
                })
              )
            }
          }
          else{
            if(form.checkValidity()){
              toast.error("There are no valid changes")
            }
          }
        return
      case "profile":
        dispatch(
          attendeeUpdateStatus({
            model,
            callback: (data) => {
              if (data?.responsedesc === "Success") {
                setRemarkDeatils("")
                toast.success(`Profile ${model.attdstatus==="1"? "enabled": "disabled"} successfully`)
                getTableData()
                setTimeout(() => {
                  setWarningPopup(false)
                }, 50)
              }
              else {
                toast.errror(data?.responsedesc + " Please try again")
              }
            }
          })
        )
        return
      case "geo location":
        dispatch(
          attendeeUpdateGeo({
            model,
            callback: (data) => {
              if (data?.responsedesc === "Success") {
                setRemarkDeatils("")
                toast.success(`Geo location ${model.status==="1"? "enabled": "disabled"} successfully`)
                getTableData()
                setWarningPopup(false)
              }
              else {
                toast.errror(data?.responsedesc + " Please try again")
              }
            }
          })
        )
        return
      case "name":
      case "mobile number":
        dispatch(
          attendeeSingleStaff({
            model,
            callback: (data) => {
              setSingleInfoData(data.staffdata[0])
              setShowPopup(true)
            }
          })
        )
      default:
        return
    }
  }

  const addStaff = (data) => {
    setisEdit(!isEdit)
    setShowPopup(false)
    getTableData()
    toast.success("success")
  }
  function handleClick(event, data, RowNo, columnName, columnNo) {
    const rowdata = staffFullTableData.find(i => i.attdcode === data["Staff code"])
    setSelectedRow(RowNo)
    setSingleInfoData({})
    setCallType(columnName.toLowerCase())
    setSelectedRole(staffFullTableData[RowNo].roleid)
    setGender(data?.Gender)
    setNodalOfficer(rowdata?.isnodal)
    setRowData(rowdata)
    switch (columnName) {
      case "Edit":
        setValidated(false)
        setWarningPopup(true)
        setInfoMessage("Edit")
        setRemarkDeatils(undefined)
        setisEdit(true)
        return
      case "Profile":
        setRemarkDeatils("")
        setInfoMessage(rowdata?.attendeeStatus === "1" ? "Disable Profile" : "Enable Profile")
        setWarningPopup(true)
        return
      case "Geo Location":
        setRemarkDeatils("")
        setInfoMessage(rowdata?.attendeegeofencingStatus === "1" ? "Disable Geo Location" : "Enable Geo Location")
        setWarningPopup(true)
        return
      case "Name":
      case "Mobile Number":
        setValidated(false)
        addUpdateStaff(event, {}, staffFullTableData[RowNo]?.aid, columnName.toLowerCase())
        return
      default:
        setShowPopup(true)
        return
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if(name!=="remarks"){
      setDataChange(true)
    }
    if (name === "gender") setGender(value)
    if (name === "isnodal") setNodalOfficer(value)
    if(name==="designation") OnSelectDesignation(e)
    if(name==="roleid") OnSelectRole(e)
    setRowData({ ...rowData, [name]: value })
  }
  const handleAreUsure = (e) => {
    switch (CallType) {
      case "edit":
        setWarningPopup(false)
        setShowPopup(true)
        break
      case "profile":
        if (remarkDeatils === "") {
          toast.error("Remarks Cannot be empty")
          break
        }
        addUpdateStaff(e)

        break
      case "geo location":
        if (remarkDeatils === "") {
          toast.error("Remarks Cannot be empty")
          break
        }
        addUpdateStaff(e)

        break
      default:
        return
    }
  }
  function textColor() {
    switch (CallType) {
      case "profile":
        return rowData?.attendeeStatus === "1" ? "0" : "1"
      case "geo location":
        return rowData?.attendeegeofencingStatus === "1" ? "0" : "1"
      case "edit":
        return "0"
      default:
        return "1"
    }
  }
  const closeModal = () => {
    setRowData({})
    setShowPopup(false);
    setisEdit(false)
  }
  return (
    <>
      <AreUSureModal
        showPopup={warningPopup}
        setShowPopup={setWarningPopup}
        titleText={infoMessage}
        handleClick={handleAreUsure}
        approvereject = {textColor()}
        setRemarkDetails={setRemarkDeatils}
        remarkDetails={remarkDeatils}
      />
      <AddUpdateModal
        showPopup={showPopup}
        deptDropDown={deptDropDown}
        orgDropDown={orgDropDown}
        designationDropDown={designationDropDown}
        roleDropDown={roleDropDown}
        SelectedDesignation={SelectedDesignation}
        SelectedRole={SelectedRole}
        OnSelectDesignation={OnSelectDesignation}
        OnSelectRole={OnSelectRole}
        SelectedDept={SelectedDept}
        SelectedOrg={SelectedOrg}
        OnSelectDept={OnSelectDept}
        OnSelectOrg={OnSelectOrg}
        addUpdateStaff={addUpdateStaff}
        isEdit={isEdit}
        rowData={rowData}
        gender={gender}
        setGender={setGender}
        nodalOfficer={nodalOfficer}
        setNodalOfficer={setNodalOfficer}
        singleInfoData={singleInfoData ? singleInfoData : {}}
        handleChange={handleChange}
        FormDeatils={FormDeatils}
        gotoedit={gotoedit}
        validated={validated}
        setValidated={setValidated}
        addLabel={addLabel}
        closeModal={closeModal}
      />

      <Card className="col-12"
        style={{ marginTop: "1rem" }}
      >
        <Row className="m-4">
          <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>Department*</Form.Label>
            <Form.Select id="disabledSelect" value={SelectedDept} onChange={OnSelectDept}>
              <option disabled value={0}>Select Department</option>
              {
                deptDropDown?.map((item, idx) =>
                  <option key={idx} value={item?.deptid}>{item?.deptname}</option>
                )
              }
            </Form.Select>
          </FormGroup>
          {/* <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>
            {`${addLabel==="TMC"?"Zone*":"College*"}`}
              </Form.Label>
            <Form.Select id="disabledSelect" value={SelectedOrg} onChange={OnSelectOrg}>
              <option disabled value={0}>Select {`${addLabel==="TMC"?"Zone*":"College*"}`}</option>
              {
                orgDropDown?.sort((a, b)=>  a.collegename.localeCompare(b.collegename))?.map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                )
              }
            </Form.Select>
          </FormGroup> */}
            <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>
            Institute
              </Form.Label>
            <Form.Select id="disabledSelect" value={SelectedOrg} onChange={OnSelectOrg}>
              <option disabled value={0}>Select Institute</option>
              {
                orgDropDown?.sort((a, b)=>  a.collegename.localeCompare(b.collegename))?.map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                )
              }
            </Form.Select>
          </FormGroup>
        </Row>
      </Card>
      <Card>
        {
          StaffTableData.length > 0 ?
            <ReportTable
              title={`Staff`}
              tableData={StaffTableData}
              clickableColumns={[[0, 1, 8, 9,10], handleClick]}
              StyledColumns={[
                [0, 1, 7, 8, 9,10],
                [" wrap underline", "underline","wrap", "custom-td-txt wrap", "custom-td-txt", "badges1 w-100"],
              ]}
              // filterOptions={filterOptions}
              // selectFilteroption={selectFilteroption}
              // onSelectFilteropion={onSelectFilteropion}
              fileName={`${SelectedDept} Staff List`}
              openModal={openModal}
              buttonText={"Add Staff"}

            /> :
            (StaffTableData.length == 0 && SelectedOrg !== "0") ?
              <Card>
                <div className="d-flex mt-4 me-3 justify-content-end">
                  <Button className="mb-2 " onClick={() => openModal("add")}>ADD Staff</Button>
                </div>
              </Card> : <></>
        }
      </Card>
    </>
  );
}

export default AttendeeStaff;
