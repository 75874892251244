import React, { useEffect } from 'react'
import ReportTable from '../components/Table'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import AreUSureModal from "../components/Modals/Duplicates";
import { adminNotificationListAction, adminNotificationReadListAction, adminPushNotificationAction } from '../Store/SagaActions/NotificationSagaAction';
import { Breadcrumb, Button, Card, Col, Row } from 'react-bootstrap';

const AdminNotification = () => {

  const dispatch = useDispatch()
  const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;

  const [notificationTableData, setNotificationTableData] = useState([])
  const [AreuSurePopup, setAreuSurePopup] = useState(false);
  const [breadcrumbDisplay,setBreadcrumbDisplay]=useState(false)


    const callingNotificationReadAction=(model)=>{
      dispatch(
        adminNotificationReadListAction({
          model,
          callback:(data)=>{
              setBreadcrumbDisplay(true)
              setNotificationTableData(data?.details)
          }
        })
      )
    }
 
  const callingNotificationList = () => {
    setBreadcrumbDisplay(false)
    if (userRole === "ADMIN") {
      dispatch(
        adminNotificationListAction({
          callback: (data) => {
            setNotificationTableData(data?.details)
          }
        })
      )
    }
  }
  const openModal = (type) => {
    setAreuSurePopup(true)
  }

  const handleClick = (e, data, row, header, col) => {
    if(data?.totalRead!=="0"){
      let model={
        unid:data?.unid
      }
      callingNotificationReadAction(model)
    }
  }

  const callingSendNotification = () => {
    dispatch(
      adminPushNotificationAction({
        callback: (data) => {
          if (data?.responsecode) {
            toast.success(data?.responsedesc)
            callingNotificationList()
          }
          else toast.error(data?.responsedesc)
          setAreuSurePopup(false)
        }
      })
    )
    setAreuSurePopup(false)
  }

  useEffect(() => {
    callingNotificationList()
  }, [])


  return (
    <div className='mt-3'>
       <Row className="row-sm mt-4">
          <Col xl={12} lg={12} md={12} sm={12}>
            <Card>
              <Card.Body className="iconfont text-start d-flex">
              <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            {/* <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={useNavigate(``+"/dashboard")}> */}
            <Breadcrumb.Item className="breadcrumb-item tx-15"
             onClick={callingNotificationList}
             >
              Home
            </Breadcrumb.Item>
            {
              breadcrumbDisplay&&
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              Total Read
            </Breadcrumb.Item>
            }
            </Breadcrumb>
        </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      <AreUSureModal
        showPopup={AreuSurePopup}
        setShowPopup={setAreuSurePopup}
        customText={`Are You Sure you want to send ?`}
        handleClick={callingSendNotification}
        style={{
          zIndex: "9991"
        }}
      />

      {notificationTableData.length > 0 ?
        <div className="main justify-content-between">
          <ReportTable
            title={'Notifications List'}
            tableData={notificationTableData}
            fileName={"Notification List"}
            openModal={openModal}
            clickableColumns={[[5], handleClick]}
            StyledColumns={[
              [2,5],
              [" wrap","text-info"],
            ]}
            buttonText={"Send Upgrade Notification"}
          />
        </div>
        :
            (notificationTableData.length === 0 ) ?
              <Card>
                <div className="d-flex mt-4 me-3 justify-content-end">
                  <Button className="mb-2 " onClick={() => openModal("add")}>Send Upgrade Notification</Button>
                </div>
              </Card> : <></>
      }

    </div>
  )
}
export default AdminNotification