import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Badge, Breadcrumb, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { monthlyreportdownload, listOfDistrictMandalsSagaAction } from "../Store/SagaActions/TableDetailSagaAction"
import toast from "react-hot-toast";
import ReportHeader from "../components/Table/ReportHeader";
import ReportCards from "../components/Table/ReportCards";
import ReportTable from "../components/Table";
import { Assign } from "../utils";
import LoaderComp from "../components/LoaderComp/LoaderComp";
import { pathName } from "../utils/Path";


const MonthlyDownloadReport = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;

  const departmentList = JSON.parse(sessionStorage.getItem("deptdata"))

  const moveToDashboard = () => {
    navigate(pathName.dashboard);
  };

  const moveToReport = () => {
    navigate("/details/");
  };

  const handleClick = (url) => {
    window.open(url, '_blank')
    
  };
  const ToolTipTrigger = (text, Icon) => {
    return (

      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip">{text}</Tooltip>}
      >
        {Icon}
      </OverlayTrigger>
    );

  };

  const Status = (status, url) => {
    switch (status) {
      case "1":
        return <svg
          width="24"
          height="24"
          fill="#77C3EC"
          style={{ marginLeft: "30px" }}

        ><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        </svg>
      case "2":
        return <svg width="24"
          height="24"
          fill="orange"
          style={{ marginLeft: "30px" }}
        ><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"></path>
        </svg>
      case "3":
        return <svg
          onClick={() => handleClick(url)}
          width="24"
          height="24"
          fill="#5cd3b9"
          style={{ marginLeft: "30px" }}
        ><path d="M19 9h-4V3H9v6H5l7 7 7-7zm-8 2V5h2v6h1.17L12 13.17 9.83 11H11zm-6 7h14v2H5z"></path></svg>

      case "4":
        return <svg width="24"
          height="24"
          fill="red"
          style={{ marginLeft: "30px" }}
        >
          <path d="M16.5,9c-0.42,0-0.83,0.04-1.24,0.11L1.01,3L1,10l9,2l-9,2l0.01,7l8.07-3.46C9.59,21.19,12.71,24,16.5,24 c4.14,0,7.5-3.36,7.5-7.5S20.64,9,16.5,9z M16.5,22c-3.03,0-5.5-2.47-5.5-5.5s2.47-5.5,5.5-5.5s5.5,2.47,5.5,5.5S19.53,22,16.5,22 z"></path>
          <polygon points="18.27,14.03 16.5,15.79 14.73,14.03 14.03,14.73 15.79,16.5 14.03,18.27 14.73,18.97 16.5,17.21 18.27,18.97 18.97,18.27 17.21,16.5 18.97,14.73"></polygon>
        </svg>
      default:

        return "Invalid"
    }
  }

  const Show = (status) => {
    switch (status) {
      case "1":
        return "Created"
      case "2":
        return "In Progress"
      case "3":
        return "Completed"
      case "4":
        return "Error"

      default:
        return "Invalid"
    }
  }

  //calling apis
  const totalMonthlyDetails = (model, _courseOptions) => {

    settdata([])
    dispatch(monthlyreportdownload({
      model,
      callback: (data) =>
        MonthlydetailsActionResp(data, _courseOptions)
    }))

  }

  const departmentCourseName = (reportparam, _courseOptions) => {
    const classid = reportparam.split(",")[1]
    const deptid = reportparam.split(",")[0]
    return _courseOptions !== undefined ? _courseOptions.find(i => (classid === `${i.value}`))?.label : departmentList.find(i => (deptid === `${i.deptid}`))?.deptname
  }
  const [tdata, settdata] = useState([])
  const MonthlydetailsActionResp = (data, _courseOptions) => {
    if (data?.length > 0) {
      let tabledatas = data?.reverse().map((item, idx) => {
        const { reportName, jobStartedAt, jobCompletedAt, reportStatus, createdDate, downloadURL, reportParameters } = item
        const jobStarted = jobStartedAt ? moment(jobStartedAt, "DD-MM-YYYY HH:mm:ss")?.format("DD-MM-YY, hh:mm:ss A") : '-'
        const CompletedJob = jobCompletedAt ? moment(jobCompletedAt, "DD-MM-YYYY HH:mm:ss")?.format("DD-MM-YY, hh:mm:ss A") : '-'
        const dept = departmentCourseName(reportParameters, _courseOptions)
        const deptCourse = userRole === "PRINCIPAL" ? "Course" : "Department"
        return ({
          "sno": idx + 1,
          "Report Type": reportName,
          // [deptCourse]: dept,
          "Job Started": jobStarted,
          "Job Completed": CompletedJob,
          "Download": ToolTipTrigger(
            reportStatus==="4"?"Please try to download again":
            reportStatus==="3"?"Download":"Wait", Status(reportStatus, downloadURL)),
          "status": Show(reportStatus),
        })
      }
      );

      settdata(tabledatas);
    }
    else {
      settdata([]);
      toast.error("No Data Found")
    }

  }

  const MonthlyReportPage = (_courseOptions) => {
    let model = {
      "period": "3"
    }
    totalMonthlyDetails(model, _courseOptions)
  };

  function getStateMonthlyDept(data) {
    const datamapped = data.data.filter(i => parseInt(i.totalstudent) > 0).map((i, idx) => ({ value: i?.courseid, label: i?.entityname }))
    MonthlyReportPage(datamapped)

  }

  const principalReport = () => {
    const rdate = moment(new Date()).format('YYYY-MM-DD')
    const deptid = sessionStorage.getItem("principalDeptid");
    // const deptid = JSON.parse(sessionStorage.getItem("loginDepartmentId"));

    let model = {
      deptid, rdate, "reporttype": "5",
      "instituteid": JSON.parse(sessionStorage.getItem("authkey")).orgid
    }
    dispatch(listOfDistrictMandalsSagaAction({ model, callback: getStateMonthlyDept }))
  }
  useEffect(() => {
    if (userRole === "PRINCIPAL") {
      principalReport()
    }
    else {
      MonthlyReportPage()
    }
  }, [])

  const [selectedDate, setSelectedDate] = useState(new Date())

  return (
    <div>
      {/* <!-- HEADER --> */}
      <LoaderComp/>
      <ReportHeader
        title={"Dashboard"}
        subtitle={localStorage.getItem("deptnameforcollegepage") + " Details"}
        pageName={"Reports"}
        moveToDashboard={moveToDashboard}
        moveToReport={moveToReport}

      />


      <div className="main justify-content-between">
        {/* <!-- CARDS --> */}
        {/* <ReportCards
          // IMAGES
          // totalImg="/assets/Images/Total College.png"
          // presentEnrollImg="/assets/Images/onBoarded Colleges.png"
          // absentNotEnrollImg="/assets/Images/not onBoarded Colleges.png"
          // // TITLE NAMES
          // totalTitle="Total Colleges"
          // enrollPresentTitle="OnBoarded colleges"
          // notEnrolledAbsentTitle="Not OnBoarded colleges"
          // districtTotalStudentsStaff={collegeDetail}
        /> */}
        {
          tdata.length > 0 &&
          <ReportTable
            title={"Monthly Report"}
            tableData={tdata}
            //    filterOptions={filterOptions}
            //  selectFilteroption={selectFilteroption}
            // onSelectFilteropion={onSelectFilteropion}
            StyledColumns={[
              [4, 5],
              ["custom-td-txt", " badges1"],
            ]}
            badgeBG={true}
            fileName={`Monthly Download Report`}
            onSync={() => {
              userRole === "PRINCIPAL" ? principalReport() : MonthlyReportPage()
            }}
          />
        }
      </div>
    </div>
  );
};

export default MonthlyDownloadReport;
