import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Form, FormGroup } from "react-bootstrap";
import { useIsMobile } from "../../utils/hooks";


const Organisationmodal = (props) => {
  const {
    showPopup,
    setShowPopup,
    deptDropDown,
    SelectedDept,
    OnSelectDept,
    addOrganisation,
    SelectedDist,
    distDropDown,
    mandalDropDown,
    SelectedMandal,
    handleChange,
    FormDeatils,
    validated,
    setValidated,
  } = props;

  const [nameClass, setNameClass] = useState("90px");
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      setNameClass("201px")
    }
    else {
      setNameClass("90px")
    }
  }, [isMobile])


 

  return (

    <Modal
      show={showPopup}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      style = {{zIndex:"9991"}}            

    >

      <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
        <Modal.Title className="text-center">
          ADD Institute
        </Modal.Title>
        <Button
          variant=""
          className="btn btn-close"
          onClick={() => {
            setShowPopup(false);
          }}
        >
          x
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-1 ">
        <Card className="mt-3">
          <Form noValidate
            className="needs-validation"
            onSubmit={(e) => {
              addOrganisation(e, FormDeatils)
              setValidated(true);

            }}
            validated={validated}
          >
            <div className="row" style={{ height: nameClass }}>
              <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>Department*</Form.Label>
                <Form.Select id="disabledSelect"
                  value={SelectedDept}
                  name={"deptid"}
                  onChange={OnSelectDept}
                  required
                >
                  <option disabled value={0}>Select Department</option>
                  {
                    deptDropDown?.map((item, idx) =>
                      <option key={idx} value={item?.deptid}>{item?.deptname}</option>
                    )
                  }
                </Form.Select>

              </FormGroup>
              <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>District*</Form.Label>

                <Form.Select
                  id="disabledSelect"
                  name={"district"}
                  value={SelectedDist}
                  onChange={handleChange}
                  required

                >
                  <option value="">Select District</option>
                  {
                    distDropDown?.map((item, idx) => <option key={idx} value={item?.distorgid}>{item?.distname}</option>
                    )
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select district.
                </Form.Control.Feedback>
              </FormGroup>
            </div>

            <div className="row" style={{ height: nameClass }}>
              <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>Mandal*</Form.Label>
                <Form.Select
                  id="disabledSelect"
                  value={SelectedMandal}
                  name={"mandalorgid"}
                  onChange={handleChange}
                  required
                  >
                  <option value="" >Select Mandal</option>
                  {
                    mandalDropDown?.map((item, idx) => <option key={idx} value={item?.mandalorgid}>{item?.mandalname}</option>
                    )
                  }

                </Form.Select>
                {
                  (SelectedDist==="0")?
                <Form.Control.Feedback type="invalid">
                  Please select district first.
                </Form.Control.Feedback>
                :
                <Form.Control.Feedback type="invalid">
                  Please select mandal.
                </Form.Control.Feedback>

                }
              </FormGroup>
              <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>Institute Code</Form.Label>
                <Form.Control
                  className="form-control"
                  placeholder="Enter Institute Code"
                  type="text"
                  name={"orgcode"}
                  onChange={handleChange}
                  maxLength= {20}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Institute code.
                </Form.Control.Feedback>
              </FormGroup>


            </div>

            <div className="row" style={{ height: nameClass }}>

              {/* <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>Organization ID</Form.Label>
                <Form.Control
                  className="form-control"
                  placeholder="Enter Organization ID"
                  type="number"
                  name="orgid"
                  onChange={handleChange}
                  minLength="8"
                  required
                /> 

               </FormGroup> */}
              <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>Institute Name*</Form.Label>
                <Form.Control
                  className="form-control"
                  placeholder="Enter Institute Name"
                  name={"orgname"}
                  required
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Institute name.
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>Latitude*</Form.Label>
                <Form.Control
                  className="form-control"
                  placeholder={"Eg : 12.00000"}
                  type="text"
                  name={"latitude"}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide valid latitude.
                </Form.Control.Feedback>
              </FormGroup>



            </div>
            <div className="row" style={{ height: nameClass }}>
              <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                <Form.Label>Longitude*</Form.Label>
                <Form.Control
                  className="form-control"
                  placeholder={"Eg : 12.00000"}
                  type="text"
                  name={"longitude"}
                  required
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide valid longitude.
                </Form.Control.Feedback>

              </FormGroup>

            </div>

            <div className="row" >
              <Button
                disabled={false}
                type='submit'
                className="btn "
                style={{ position: "absolute", right: "16px", bottom: "6px" }}
              >
                Add

              </Button>
            </div>

          </Form>

        </Card>

      </Modal.Body>

    </Modal>
  );
};
export default Organisationmodal;
