
import toast from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "../../services/axios";
import { getAPI, postAPI } from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";
import {
    ACTION_POST_HOLIDAY_LIST_REQ,
    ACTION_POST_HOLIDAY_ADD_REQ,
    ACTION_POST_HOLIDAY_UPADATE_REQ,
    ACTION_POST_HOLIDAY_DELETE_REQ,
    ACTION_POST_STATE_HOLIDAY_LIST_REQ,
    ACTION_POST_STATE_HOLIDAY_ADD_REQ,
    ACTION_POST_STATE_HOLIDAY_UPADATE_REQ,
    ACTION_POST_STATE_HOLIDAY_DELETE_REQ,
} from '../SagaActions/SagaActionTypes'

function* postHolidayListReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));

    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/list",
        action?.payload?.model
    );
    // action?.payload?.callback(resp);
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }



}

function* postHolidayAddReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/add",
        action?.payload?.model
    );
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }
}
function* postHolidayUpdateReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/update",
        action?.payload?.model
    );
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }
}

function* postHolidayDeleteReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/delete",
        action?.payload?.model
    );
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }
}

function* stateHolidayListReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/list-master",
        action?.payload?.model
    );
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }
}

function* stateHolidayAddReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/add-master",
        action?.payload?.model
    );
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }
}

function* stateHolidayUpdateReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/update-master",
        action?.payload?.model
    );
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }
}

function* stateHolidayDeleteReqSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
        postAPI,
        process.env.REACT_APP_BASE_URL +
        "/api/holidays/delete-master",
        action?.payload?.model
    );
    try {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp);
            yield put(actionReqResStatusLoaderSagaAction(false));
        }
    }
    catch (err) {
        yield put(actionReqResStatusLoaderSagaAction(false));
        if (err.response) {
            action?.payload?.callback([]);
        }
    }
}


export default function* HolidayWatcherSaga() {
    yield takeLatest(ACTION_POST_HOLIDAY_ADD_REQ, postHolidayAddReqSaga);
    yield takeLatest(ACTION_POST_HOLIDAY_LIST_REQ, postHolidayListReqSaga);
    yield takeLatest(ACTION_POST_HOLIDAY_UPADATE_REQ, postHolidayUpdateReqSaga);
    yield takeLatest(ACTION_POST_HOLIDAY_DELETE_REQ, postHolidayDeleteReqSaga);
    yield takeLatest(ACTION_POST_STATE_HOLIDAY_LIST_REQ, stateHolidayListReqSaga);
    yield takeLatest(ACTION_POST_STATE_HOLIDAY_ADD_REQ, stateHolidayAddReqSaga);
    yield takeLatest(ACTION_POST_STATE_HOLIDAY_UPADATE_REQ, stateHolidayUpdateReqSaga);
    yield takeLatest(ACTION_POST_STATE_HOLIDAY_DELETE_REQ, stateHolidayDeleteReqSaga);
}

