import React from "react";
import { Badge } from "react-bootstrap";

export const COLUMNS = [
  {
    Header: "District Name",
    accessor: "DistrictName",
    className: " ",
  },
  {
    Header: "No of Institutes",
    accessor: "CollegeCount",
    className: " ",
  },
  {
    Header: "Enrolled Student",
    accessor: "TotalStaff",
    className: " ",
  },
  {
    Header: "Present Student",
    accessor: "EnrolledStaff",
    className: " ",
  },
  {
    Header: "Absent Student",
    accessor: "NotEnrolledStaff",
    className: " ",
  },
  {
    Header: "Action",
    accessor: "Status",
    className: " ",
  },
  {
    Header: "Staff ID",
    accessor: "StaffID",
    className: " ",
  },
];



export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <>
      <span className="d-flex"
        style={{ minWidth: "100px" }}
      >
        <input
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          className="form-control"
          placeholder="Search..."
        />
      </span>
    </>
  );
};


export const Badges = (props) => {
  return (<div

    className="d-flex ms-auto align-items-center justify-content-center mb-4 me-1 flex-wrap"
  >
    <div className="m-2" onClick={() => props?.onChangeHandler("1", "Present", "P")} role="button">
      <Badge bg="" className=" bg-success " >
        {" "}
        P
      </Badge>{" "}
      Present
    </div>
    <div className="m-2" onClick={() => props?.onChangeHandler("2", "Absent", "A")} role="button">
      <Badge bg="" className=" bg-danger ">
        {" "}
        A
      </Badge>{" "}
      Absent
    </div>
    <div className="m-2" onClick={() => props?.onChangeHandler("3", "Enrolled", "Enrolled")} role="button">
      <Badge bg="" className="bg-info">
        {" "}
        E
      </Badge>{" "}
      Enrolled
    </div>
    <div className="m-2" onClick={() => props?.onChangeHandler("4", "Not Enrolled", "NE")} role="button">
      <Badge bg="" className="bg-info">
        {" "}
        NE
      </Badge>{" "}
      Not Enrolled
    </div>
    <div className="m-2">
      <Badge bg="" className=" holiday ">
        {" "}
        H
      </Badge>{" "}
      Holiday
    </div>
    <div className="m-2">
      <Badge bg="" className=" sunday ">
        {" "}
        SU
      </Badge>{" "}
      Sunday
    </div>

    <div className="m-2">
      <Badge bg="" className=" sunday ">
        {" "}
        SS
      </Badge>{" "}
      Second Saturday
    </div>
    {/* <div className="m-2" onClick={()=>props?.onChangeHandler("5","On Leave","OL")} role="button">
      <Badge bg="" className=" sunday ">
        {" "}
        OL
      </Badge>{" "}
      On Leave
    </div> */}
    {props.type === "staff" &&
      <div className="m-2" onClick={() => props?.onChangeHandler("5", "On Duty", "OD")} role="button">
        <Badge bg="" className=" bg-warning ">
          {" "}
          OD
        </Badge>{" "}
        ON Duty
      </div>
    }
    {/* {props.type === "student" &&
      <div className="m-2"  onClick={()=>props?.onChangeHandler("6")} role="button">
        <Badge bg="" className=" bg-warning ">
          {" "}
          AA
        </Badge>{" "}
        Authorised Absent
      </div>
    } */}
  </div>)
}

export const badgecolors = (type) => {
  switch (type) {
    case "P":
      return "bg-success";
    case "A":
      return "bg-danger";
    case "NE":
      return "bg-info";
    case "H":
      return "holiday";
    case "OD":
      return "bg-warning";
    case "AA":
      return "bg-warning";
    case "SU":
      return "bg-warning";
    case "SL":
      return "bg-warning";
    case "CL":
      return "bg-warning";
    case "HDOD":
      return "bg-warning";
    case "Assign":
      return "bg-danger";
    case "In Progress":
      return "bg-orange";
    case "Pending":
      return "bg-info";
    case "Closed":
      return "bg-success";
    case "Completed":
      return "bg-success";
    case "Created":
      return "bg-info";
    case "Error":
      return "bg-danger";

    case "0":
      return "bg-danger";
    case "1":
      return "bg-orange";
    case "2":
      return "bg-info";
    case "3":
      return "bg-success";
    default:
      return "";
  }
}

export const badgevalue = (type) => {
  switch (type) {
    case "P":
      return "Present";
    case "A":
      return "Absent";
    case "NE":
      return "Not Enrolled";
    case "H":
      return "Holiday";
    case "OD":
      return "On Duty";
    case "AA":
      return "Authorised Absent";
    case "SS":
      return "Second Saturday";
    case "SU":
      return "Sunday";
    default:
      return " ";
  }
}