import React, { useEffect } from 'react'
import { Breadcrumb, Card, Col, Row, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { leaveSummaryReportAction } from '../../Store/SagaActions/TableDetailSagaAction'

const LeaveSummaryPresenter = () => {
    const dispatch = useDispatch()
    const [leaveSummaryData, setLeaveSummaryData] = useState({})
    const [selectedFromDate, setSelectedFromDate] = useState(new Date())
    const [selectedToDate, setSelectedToDate] = useState(new Date())
    const [breadcrumbData, setBreadCrumbData] = useState([
      { value: JSON.parse(sessionStorage.getItem("authkey"))?.orgid, label: 'Home' }])
    const [leaveSummaryTableData, setLeaveSummaryTableData] = useState([])
    let homepageOrgid = JSON.parse(sessionStorage.getItem("authkey")).orgid;
  
    const onSelectFromDate = async (e) => {
      setSelectedFromDate(e);
    };
    const onSelectToDate = async (e) => {
      setSelectedToDate(e)
    };
  
    const callingLeaveSummaryData = (orgid) => {
      setLeaveSummaryTableData([])
      let model = 
      {
        "orgid":orgid === undefined ? homepageOrgid : orgid,
        "fromdate":moment(selectedFromDate)?.format("YYYY-MM-DD"),
        "todate":moment(selectedToDate)?.format("YYYY-MM-DD")
    }
      dispatch(
        leaveSummaryReportAction({
          model,
          callback: (data) => {
            if (data?.status || data?.details?.length===0) {
              toast.error("No Data Found")
              // const temp = [...breadcrumbData]
              // temp.splice(breadcrumbData?.length, 1)
              // setBreadCrumbData([...temp])
              return
            }
            setLeaveSummaryData(data)
            const { details } = data
            const datamapped = details.map((i, idx) => {
              const {attdid,attdcode, attdname,designation,cancelled,approved, pending, rejected} = i
              const temp = {
                "Att_Id": attdid,
                "Att_Code": attdcode,
                "Att_Name":attdname,
                "designation":designation,
                "cancelled":cancelled,
                "approved": approved,
                "pending":pending,
                "rejected":rejected
              }
              return temp;
            })
            setLeaveSummaryTableData(datamapped)
          },
        })
      );
    }
  
    const handleClick = (e, data, row, header, col) => {
      setBreadCrumbData([...breadcrumbData, { value: data?.orgid, label: data?.orgname }])
      callingLeaveSummaryData(data?.orgid)
    }  
  
    const HandleBreadCrumb = (idx) => {
      callingLeaveSummaryData(breadcrumbData[idx]?.value)
      const temp = [...breadcrumbData]
      temp.splice(idx + 1, Infinity)
      setBreadCrumbData([...temp])
    }
  
    const Breadcrumbs = () => {
      return <Breadcrumb className="breadcrumb-style mg-b-0 d-flex">
        {breadcrumbData?.map((i, idx) => {
          if (idx === breadcrumbData?.length - 1) {
            return <Breadcrumb.Item href="#"
              active>
              {i?.label}
            </Breadcrumb.Item>
          }
          return (<Breadcrumb.Item href="#"
            onClick={() => HandleBreadCrumb(idx)}
          >
            {i?.label}
          </Breadcrumb.Item>)
        }
        )}
      </Breadcrumb>
    }

  return {
    Breadcrumbs,
    leaveSummaryData,
    leaveSummaryTableData,
    handleClick,
    selectedFromDate,
    selectedToDate,
    onSelectFromDate,
    onSelectToDate,
    callingLeaveSummaryData,
  }
}

export default LeaveSummaryPresenter
