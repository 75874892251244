export const Assign = (status) => {
    switch (status) {
        case "0":
            return "Assign"
        case "1":
            return "In Progress"
        case "2":
            return "Pending"
        case "3":
            return "Closed"
        case "Assign":
            return "text-danger"
        case "In Progress":
            return "text-orange"
        case "Pending":
            return "text-info"
        case "Closed":
            return "text-success"
        default:
            return "Invalid"
    }
}

export const options1 = [
    { value: "2", label: "CCE" },
    { value: "6", label: "Agriculture" },
    { value: "7", label: "Animal Husbandry" },
];

export const options2 = [
    { value: "1", label: "Student" },
    { value: "2", label: "Staff" },
];
export const options3 = [
    { value: "2", label: " Enrollment" },
    { value: "1", label: " Attendance" },
];
export const options4 = [
    { value: "1", label: " 1st Year" },
    { value: "2", label: " 2nd Year" },
    { value: "3", label: " 3rd Year" },
    // { value: "4", label: " 4th Year" },
];
export const despOptions=[
    { value: "1", label: "In Progress" },
    { value: "2", label: "Pending" },
    { value: "3", label: "Closed" },
]

//style for dropdown
export const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };