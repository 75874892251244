import toast from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "../../services/axios";
import {
    ACTION_POST_AUDITLOGS_USER_REQ,
    ACTION_POST_AUDITLOGS_LIST_REQ,
} from "../SagaActions/SagaActionTypes";
import { getAPI, postAPI } from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";


function* auditLogsUserSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/auditlogs/users",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback(err);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

function* auditLogsListSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));

    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/auditlogs/list",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback(err);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
export default function* AuditLogsWatcherSaga() {
    yield takeLatest(ACTION_POST_AUDITLOGS_USER_REQ, auditLogsUserSaga);
    yield takeLatest(ACTION_POST_AUDITLOGS_LIST_REQ, auditLogsListSaga);
}