import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Form, FormGroup } from "react-bootstrap";
import { useIsMobile } from "../../utils/hooks";
import moment from "moment";
import { shortNames } from "../../utils/shortNames";


const Studentmodal = (props) => {
  const {
    showPopup,
    deptDropDown,
    orgDropDown,
    classDropDown,
    SelectedDept,
    SelectedOrg,
    SelectedClass,
    OnSelectDept,
    OnSelectOrg,
    OnSelectClass,
    addUpdateStudent,
    isEdit,
    rowData,
    gender,
    singleInfoData,
    handleChange,
    FormDeatils,
    gotoedit,
    validated,
    setValidated,
    addLabel,
    closeModal,
  } = props;

  const [nameClass, setNameClass] = useState("84px");
  const isMobile = useIsMobile();


  useEffect(() => {
    if (isMobile) {
      setNameClass("201px")
    }
    else {
      setNameClass("84px")
    }
  }, [isMobile])

  const shortName = (name) => {
    return shortNames(name);
  }


  const dateSingleInfoDetail = moment(singleInfoData?.dateupdated, "DD-MM-YYYY HH:mm:ss")?.format("DD-MM-YYYY")
  return (

    <Modal
      show={showPopup}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      style={{ zIndex: "9991" }}

    >
      {
        Object.keys(singleInfoData).length > 0 ?
          <>
            <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
              <Modal.Title className="text-center">
                Student Details
                {/* {`${addLabel === "TMC" ? "Woker*" : "Student*"}`}  Details */}
              </Modal.Title>
              <Button
                variant=""
                className="btn btn-close"
                onClick={() => {
                  closeModal()
                }}
              >
                x
              </Button>
            </Modal.Header>

            <Card className="custom-card customs-cards">
              <Card.Body className=" d-md-flex bg-white">
                <div className="">
                  <span className="profile-image pos-relative">
                    <div className="avatar avatar-xxl d-none d-sm-flex bg-pink rounded-circle mt-3">
                      {shortName(singleInfoData?.attdname)}
                    </div>
                  </span>
                </div>
                <div className="my-md-auto mt-4 prof-details">
                  <h4 className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
                    {singleInfoData?.attdname === "" ? "-" : singleInfoData?.attdname}
                  </h4>
                  <p className="tx-13 text-muted ms-md-4 ms-0 mb-2 pb-2 ">
                    <span className="me-3">
                      <i className="far fa-address-card me-2"></i>
                      {singleInfoData?.attdid === "" ? "_" : singleInfoData?.attdid}
                    </span>
                    <span className="me-3">
                      <i className="fa fa-taxi me-2"></i>
                      {singleInfoData?.department === "" ? "_" : singleInfoData?.department}
                    </span>
                    <span>
                      <i className="far fa-flag me-2"></i>
                      {singleInfoData?.collegename === "" ? "_" : singleInfoData?.collegename}
                    </span>
                  </p>
                  <p className="text-muted ms-md-4 ms-0 mb-2">
                    <span>
                      <i className="fas fa-university me-2"></i>
                    </span>
                    <span className="font-weight-semibold me-2">Class:</span>
                    <span>{singleInfoData?.classname === "" ? "_" : singleInfoData?.classname}</span>
                  </p>
                  <p className="text-muted ms-md-4 ms-0 mb-2">
                    <span>
                      <i className="fa fa-phone me-2"></i>
                    </span>
                    <span className="font-weight-semibold me-2">Phone:</span>
                    <span>{singleInfoData?.mobile === "" ? "_" : singleInfoData?.mobile}</span>
                  </p>
                  <p className="text-muted ms-md-4 ms-0 mb-2">
                    <span>
                      <i className="fa fa-envelope me-2"></i>
                    </span>
                    <span className="font-weight-semibold me-2">Email:</span>
                    <span>{singleInfoData?.email === "" ? "_" : singleInfoData?.email}</span>
                  </p>
                  <p className="text-muted ms-md-4 ms-0 mb-2">
                    <span>
                      <i className="fa fa-user me-2"></i>
                    </span>
                    <span className="font-weight-semibold me-2">Gender</span>
                    <span>{singleInfoData?.gender === "" ? "_" : singleInfoData?.gender}</span>
                  </p>
                  <p className="text-muted ms-md-4 ms-0 mb-2">
                    <span>
                      <i className="fa fa-globe me-2"></i>
                    </span>
                    <span className="font-weight-semibold me-2">Date Updated</span>
                    <span>{dateSingleInfoDetail === "" ? "_" : dateSingleInfoDetail}</span>
                  </p>
                  <p className="text-muted ms-md-4 ms-0 mb-2">
                    <span>
                      <i className="fa fa-street-view me-2"></i>
                    </span>
                    <span className="font-weight-semibold me-2">User Updated</span>
                    <span>{singleInfoData?.userupdated === "" ? "_" : singleInfoData?.userupdated}</span>
                  </p>
                </div>
              </Card.Body>
              <div className="row" style={{ height: "2vh" }}>

                <Button
                  disabled={false}
                  type='submit'
                  className="btn "
                  style={{ position: "absolute", right: "16px", bottom: "6px" }}
                  onClick={gotoedit}
                >
                  Edit

                </Button>
              </div>
            </Card>
          </>
          :
          <>
            <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
              <Modal.Title className="text-center">
                {isEdit === false ? "ADD " : "Update "}
                Student
                {/* {`${addLabel === "TMC" ? "Worker*" : "Student*"}`} */}
              </Modal.Title>
              <Button
                variant=""
                className="btn btn-close"
                onClick={() => {
                  closeModal()
                }}
              >
                x
              </Button>
            </Modal.Header>
            <Modal.Body className="pt-1 ">
              <Card className="mt-3">
                <Form noValidate
                  className="needs-validation"
                  onSubmit={(e) => {
                    addUpdateStudent(e, FormDeatils)
                    setValidated(true);
                  }}
                  validated={validated}
                >
                  <div className="row" style={{ height: nameClass }}>
                    {isEdit === false ? <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label>Department*</Form.Label>
                      <Form.Select id="disabledSelect"
                        value={SelectedDept}
                        name={"deptid"}
                        onChange={OnSelectDept}
                        disabled={isEdit}
                      >
                        <option disabled value={0}>Select Department</option>
                        {
                          deptDropDown?.map((item, idx) =>
                            <option key={idx} value={item?.deptid}>{item?.deptname}</option>
                          )
                        }
                      </Form.Select>

                    </FormGroup> :
                      <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                        <Form.Label>Department</Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder={deptDropDown?.find(i => i.deptid === parseInt(SelectedDept)).deptname}
                          type="text"
                          // minLength="10"
                          name={"deptid"}
                          //  onChange={handleChange}
                          disabled
                        />

                      </FormGroup>

                    }

                    {isEdit === false ? <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label>Institute*</Form.Label>

                      <Form.Select
                        id="disabledSelect"
                        value={SelectedOrg}
                        onChange={OnSelectOrg}
                        disabled={isEdit}

                      >
                        <option disabled value={0}>Select Institute</option>
                        {
                          orgDropDown?.map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                          )
                        }
                      </Form.Select>
                    </FormGroup> :
                      <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                        <Form.Label>Organisation*</Form.Label>
                        <Form.Control
                          className="form-control"
                          onChange={OnSelectOrg}
                          type="text"
                          disabled
                          placeholder={orgDropDown?.find(i => i.collegeorgid === SelectedOrg).collegename}
                        />

                      </FormGroup>}
                  </div>

                  <div className="row" style={{ height: nameClass }}>
                    {isEdit === false ? <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label>
                        Class*
                        {/* {`${addLabel === "TMC" ? "Zone*" : "Class*"}`} */}

                      </Form.Label>
                      <Form.Select
                        id="disabledSelect"
                        value={SelectedClass}
                        name={"classorgid"}
                        disabled={isEdit}
                        onChange={OnSelectClass}>
                        <option disabled value={0}>Select {`${addLabel === "TMC" ? "Zone*" : "Class*"}`}</option>
                        {
                          classDropDown?.map((item, idx) => <option key={idx} value={item?.classorgid}>{item?.classname}</option>
                          )
                        }

                      </Form.Select>
                    </FormGroup> :
                      <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                        <Form.Label>
                          Class*
                          {/* {`${addLabel === "TMC" ? "Zone*" : "Class*"}`} */}

                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          name={"classorgid"}
                          onChange={OnSelectClass}
                          type="text"
                          disabled
                          placeholder={classDropDown?.find(i => i.classorgid === SelectedClass).classname}
                        />

                      </FormGroup>}

                    <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label>
                        Student Code*
                        {/* {`${addLabel === "TMC" ? "Worker*" : "Student*"}`} Code */}
                      </Form.Label>
                      <Form.Control
                        className="form-control"
                        placeholder={isEdit ? rowData?.attdcode : "Enter code"}
                        type="text"
                        id="attendeeCode"
                        name={"attdcode"}
                        onChange={handleChange}
                        maxLength={20}
                        disabled={isEdit}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide code.
                      </Form.Control.Feedback>
                    </FormGroup>


                  </div>

                  <div className="row" style={{ height: nameClass }}>
                    <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label>
                        Student ID*
                        {/* {`${addLabel === "TMC" ? "Woker*" : "Student*"}`} ID */}
                      </Form.Label>
                      <Form.Control
                        className="form-control"
                        placeholder={isEdit ? rowData?.attdid : "Enter ID"}
                        type="text"
                        id="attendeeid"
                        name="attdid"
                        maxLength={10}
                        onChange={handleChange}
                        disabled={isEdit}
                        pattern="[0-9]+"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide ID.
                      </Form.Control.Feedback>

                    </FormGroup>
                    <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label><strong>
                        Student Name*
                        {/* {`${addLabel === "TMC" ? "Woker*" : "Student*"}`}  Name* */}
                      </strong></Form.Label>
                      <Form.Control
                        className="form-control"
                        placeholder="Enter name"
                        value={rowData?.attdname}
                        id="email"
                        // pattern="[A-Za-z]{3}"
                        name={"attdname"}
                        required={isEdit ? false : true}
                        pattern="[A-Za-z. ]+"
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide student name.
                      </Form.Control.Feedback>

                    </FormGroup>


                  </div>
                  <div className="row" style={{ height: nameClass }}>
                    <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label><strong>Email ID*</strong></Form.Label>
                      <Form.Control
                        className="form-control"
                        placeholder="Enter email id"
                        value={rowData?.email}
                        type="email"
                        id="email"
                        name={"email"}
                        onChange={handleChange}
                        required={isEdit ? false : true}
                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                      </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                      <Form.Label><strong>Mobile Number*</strong></Form.Label>
                      <Form.Control
                        className="form-control"
                        placeholder="Enter 10 digit mobile number"
                        value={rowData?.mobile}
                        type="text"
                        id="email"
                        pattern="[0-9]+"
                        minLength={10}
                        maxLength={10}
                        name={"mobile"}
                        required={isEdit ? false : true}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide valid 10 digit mobile number.
                      </Form.Control.Feedback>
                    </FormGroup>

                  </div>

                  <div className="row" style={{ height: "7vh" }}>
                    <FormGroup className="mt-3 text-sm form-group col-12 col-sm-6 d-flex justify-content-between">
                      <Form.Label><strong>Gender *</strong></Form.Label>
                      <Form.Check className="mt-2"
                        value="M"
                        name="gender"
                        type="radio"
                        aria-label="radio 1"
                        label="Male"
                        onChange={handleChange}
                        checked={gender === "M"}
                      />
                      <Form.Check className="mt-2"
                        // style={{ paddingLeft: "20px" }}
                        value="F"
                        type="radio"
                        name="gender"

                        aria-label="radio 2"
                        label="Female"
                        onChange={handleChange}
                        checked={gender === "F"}
                      />

                    </FormGroup>
                  </div>

                  <div className="row" >
                    {isEdit === true ?
                      <FormGroup className="text-start form-group col-12 col-sm-10 ">
                        <Form.Label>Remarks *</Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Enter Remarks"
                          as="textarea" rows={2}
                          id="remarks"
                          name={"remarks"}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide remarks.
                        </Form.Control.Feedback>
                      </FormGroup>
                      : null

                    }


                    <Button
                      disabled={false}
                      type='submit'
                      className="btn "
                      style={{ position: "absolute", right: "16px", bottom: "6px" }}
                    >
                      {isEdit === false ? "ADD " : "Update "}

                    </Button>
                  </div>

                </Form>

              </Card>

            </Modal.Body>
          </>
      }

    </Modal>
  );
};
export default Studentmodal;
