import axios from "axios";
import toast from "react-hot-toast";

const instance = axios.create({
  // baseURL: "https://frsdegree.ap.gov.in/",
  // baseURL: process.env.REACT_APP_BASE_URL
});

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["apikey"] = process.env.REACT_APP_API_KEY;
// instance.defaults.headers.common["apikey"] =  "6a68a5cb-226c-485e-b0f1-c7f9c1320132";
// instance.defaults.headers.common["authkey"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6InNhbnRvc2hpLnJlbnVrdW50YUBzeW50aXplbi5jb218M3wxfEpVUFVCLU1OWU9JLUdRRUVaLU5HU1lEfEFnZW50IiwibmJmIjoxNjU5NTIwMzU1LCJleHAiOjE2NTk2MDY3NTUsImlhdCI6MTY1OTUyMDM1NX0.INgQOmjU6pmV3WmDNLLXJv-zmY2PaFfuduNotgpfL3Y";
// instance.defaults.headers.common["authkey"] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFnZW50QHN5bnRpemVuLmNvbXwzfDF8SlVQVUItTU5ZT0ktR1FFRVotTkdTWUR8QWdlbnQiLCJuYmYiOjE2NDk2NTI1NTQsImV4cCI6MTY0OTczODk1NCwiaWF0IjoxNjQ5NjUyNTU0fQ.QDCsbEhJlHDe7QYDJslSOnk6qKx-S0ivWFzfWLPWTaM";
// instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';

instance.interceptors.request.use(
  request => {
    if (request?.apikey)
      request.headers["apikey"] = request?.apikey;
    const authkey = sessionStorage.getItem('authkey')
    if (authkey != undefined) {
      let data = JSON.parse(authkey);
      const authToken = data?.authToken
      request.headers["Authorization"] = "Bearer " + authToken;
    } else {
      // toast.error("invalid token please login again")
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);



instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error?.response
    if (status === 401){
      sessionStorage.setItem("openModal", true)
      window.location.replace(window.location.protocol + "//" + window.location.host);
      
    } 
    return Promise.reject(error);
  }
);

export default instance;
