import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Form, FormGroup } from "react-bootstrap";
import { useIsMobile } from "../../utils/hooks";
import moment from "moment";
import { shortNames } from "../../utils/shortNames";


const Staffmodal = (props) => {
    const {
        showPopup,
        deptDropDown,
        orgDropDown,
        designationDropDown,
        SelectedDesignation,
        OnSelectDesignation,
        SelectedDept,
        SelectedOrg,
        OnSelectDept,
        OnSelectOrg,
        addUpdateStaff,
        isEdit,
        rowData,
        gender,
        nodalOfficer,
        singleInfoData,
        handleChange,
        FormDeatils,
        gotoedit,
        validated,
        setValidated,
        addLabel,
        roleDropDown,
        SelectedRole,
        OnSelectRole,
        closeModal,
    } = props;

    const [nameClass, setNameClass] = useState("84px");
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            setNameClass("201px")
        }
        else {
            setNameClass("84px")
        }
    }, [isMobile])

    const dateSingleInfoDetail = moment(singleInfoData?.dateupdated, "DD-MM-YYYY HH:mm:ss")?.format("DD-MM-YYYY")

    const shortName = (name) => {
        return shortNames(name);
    }
    return (

        <Modal
            show={showPopup}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            style={{ zIndex: "9991" }}
        >
            {
                Object.keys(singleInfoData).length > 0 ?
                    <>
                        <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
                            <Modal.Title className="text-center">
                                Staff
                                {/* {`${addLabel === "TMC" ? "Supervisor*" : "Staff*"}`} Details */}
                            </Modal.Title>
                            <Button
                                variant=""
                                className="btn btn-close"
                                onClick={() => {
                                    closeModal()
                                }}
                            >
                                x
                            </Button>
                        </Modal.Header>
                        <Card className="custom-card customs-cards">
                            <Card.Body className=" d-md-flex bg-white">
                                <div className="">
                                    <span className="profile-image pos-relative">
                                        <div className="avatar avatar-xxl d-none d-sm-flex bg-pink rounded-circle mt-3">
                                            {/* {singleInfoData.attdname} */}
                                            {shortName(singleInfoData?.attdname)}

                                        </div>
                                    </span>
                                </div>
                                <div className="my-md-auto mt-4 prof-details">
                                    <h4 className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0">
                                        {singleInfoData?.attdname === "" ? "-" : singleInfoData?.attdname}
                                    </h4>
                                    <p className="tx-13 text-muted ms-md-4 ms-0 mb-2 pb-2 ">
                                        <span className="me-3">
                                            <i className="far fa-address-card me-2"></i>
                                            {singleInfoData?.attdid === "" ? "_" : singleInfoData?.attdid}
                                        </span>
                                        <span className="me-3">
                                            <i className="fa fa-taxi me-2"></i>
                                            {singleInfoData?.department === "" ? "_" : singleInfoData?.department}
                                        </span>
                                        <span>
                                            <i className="far fa-flag me-2"></i>
                                            {singleInfoData?.collegename === "" ? "_" : singleInfoData?.collegename}
                                        </span>
                                    </p>
                                    <p className="text-muted ms-md-4 ms-0 mb-2">
                                        <span>
                                            <i className="fa fa-phone me-2"></i>
                                        </span>
                                        <span className="font-weight-semibold me-2">Phone:</span>
                                        <span>{singleInfoData?.mobile === "" ? "_" : singleInfoData?.mobile}</span>
                                    </p>
                                    <p className="text-muted ms-md-4 ms-0 mb-2">
                                        <span>
                                            <i className="fa fa-envelope me-2"></i>
                                        </span>
                                        <span className="font-weight-semibold me-2">Email:</span>
                                        <span>{singleInfoData?.email === "" ? "_" : singleInfoData?.email}</span>
                                    </p>
                                    <p className="text-muted ms-md-4 ms-0 mb-2">
                                        <span>
                                            <i className="fa fa-user me-2"></i>
                                        </span>
                                        <span className="font-weight-semibold me-2">Gender</span>
                                        <span>{singleInfoData?.gender === "" ? "_" : singleInfoData?.gender}</span>
                                    </p>
                                    <p className="text-muted ms-md-4 ms-0 mb-2">
                                        <span>
                                            <i className="fa fa-globe me-2"></i>
                                        </span>
                                        <span className="font-weight-semibold me-2">Date Updated</span>
                                        <span>{dateSingleInfoDetail === "" ? "_" : dateSingleInfoDetail}</span>
                                    </p>
                                    <p className="text-muted ms-md-4 ms-0 mb-2">
                                        <span>
                                            <i className="fa fa-street-view me-2"></i>
                                        </span>
                                        <span className="font-weight-semibold me-2">User Updated</span>
                                        <span>{singleInfoData?.userupdated === "" ? "_" : singleInfoData?.userupdated}</span>
                                    </p>
                                    {/* <p className="text-muted ms-md-4 ms-0 mb-2">
                                        <span>
                                            <i className="fa fa-street-view me-2"></i>
                                        </span>
                                        <span className="font-weight-semibold me-2">Comment</span>
                                        <span>{singleInfoData?.userupdated === "" ? "_" : singleInfoData?.geofencing_remarks}</span>
                                    </p> */}
                                </div>
                            </Card.Body>
                            <div className="row" style={{ height: "2vh" }}>

                                <Button
                                    disabled={false}
                                    type='submit'
                                    className="btn "
                                    style={{ position: "absolute", right: "16px", bottom: "6px" }}
                                    onClick={gotoedit}
                                >
                                    Edit

                                </Button>
                            </div>
                        </Card>

                    </>
                    :
                    <>
                        <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
                            <Modal.Title className="text-center">
                                {isEdit === false ? "ADD " : "Update "}
                                Staff
                                {/* {`${addLabel === "TMC" ? "Supervisor*" : "Staff*"}`} */}
                            </Modal.Title>
                            <Button
                                variant=""
                                className="btn btn-close"
                                onClick={() => {
                                    closeModal()
                                }}
                            >
                                x
                            </Button>
                        </Modal.Header>
                        <Modal.Body className="pt-1 ">
                            <Card className="mt-3">
                                <Form noValidate
                                    className="needs-validation"
                                    onSubmit={(e) => {
                                        addUpdateStaff(e, FormDeatils)
                                        setValidated(true);
                                    }}
                                    validated={validated}
                                >
                                    <div className="row" style={{ height: nameClass }}>

                                        {isEdit === false ?
                                            <FormGroup className="text-sm form-group col-12 col-sm-6 ">

                                                <Form.Label>Department*</Form.Label>
                                                <Form.Select id="disabledSelect"
                                                    value={SelectedDept}
                                                    name={"deptid"}
                                                    onChange={OnSelectDept}
                                                >
                                                    <option disabled value={0}>Select Department</option>
                                                    {
                                                        deptDropDown?.map((item, idx) =>
                                                            <option key={idx} value={item?.deptid}>{item?.deptname}</option>
                                                        )
                                                    }
                                                </Form.Select>

                                            </FormGroup>
                                            :
                                            <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                                <Form.Label>Department</Form.Label>
                                                <Form.Control
                                                    className="form-control"
                                                    placeholder={deptDropDown?.find(i => i.deptid === parseInt(SelectedDept)).deptname}
                                                    type="text"
                                                    name={"deptid"}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </FormGroup>
                                        }

                                        {isEdit === false ?
                                            <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                                <Form.Label>
                                                Institute*
                                                    {/* {`${addLabel === "TMC" ? "Zone*" : "Institute*"}`}* */}
                                                </Form.Label>
                                                <Form.Select id="disabledSelect"
                                                    value={SelectedOrg}
                                                    name={"collegeorgid"}
                                                    onChange={OnSelectOrg}
                                                    disabled={isEdit}
                                                    size="sm">
                                                    <option disabled value={0}>Select {`${addLabel === "TMC" ? "Zone*" : "Institute*"}`}</option>
                                                    {
                                                        orgDropDown?.map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            </FormGroup> :

                                            <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                                <Form.Label>
                                                    {/* {`${addLabel === "TMC" ? "Zone*" : "Institute*"}`} */}
                                                    Institute*
                                                </Form.Label>
                                                <Form.Control
                                                    className="form-control"
                                                    //   value={SelectedOrg}
                                                    name={"collegeorgid"}
                                                    onChange={OnSelectOrg}
                                                    type="text"
                                                    disabled
                                                    placeholder={orgDropDown?.find(i => i.collegeorgid === SelectedOrg)?.collegename}
                                                />

                                            </FormGroup>
                                        }
                                    </div>
                                    <div className="row" style={{ height: nameClass }}>
                                        <FormGroup className="text-start form-group col-12 col-sm-6">
                                            <Form.Label>
                                                Staff
                                                {/* {`${addLabel === "TMC" ? "Supervisor*" : "Staff*"}`} */}
                                                &nbsp;ID</Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder={isEdit ? rowData?.attdid : "Enter Staff ID"}
                                                id="attendeeid"
                                                name="attdid"
                                                minLength={10} //make this dynmaic
                                                disabled={isEdit === true}
                                                onChange={handleChange}
                                                required={isEdit ? false : true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Staff ID.
                                            </Form.Control.Feedback>
                                        </FormGroup>

                                        <FormGroup className="text-start form-group col-12 col-sm-6">
                                            <Form.Label>
                                                Staff
                                                {/* {`${addLabel === "TMC" ? "Supervisor*" : "Staff*"}`} */}
                                                &nbsp;Code</Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder={isEdit ? rowData?.attdcode : "Enter Staff Code"}
                                                id="attendeeCode"
                                                name="attdcode"
                                                maxLength={20}
                                                onChange={handleChange}
                                                disabled={isEdit === true}
                                                required={isEdit ? false : true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide Staff code.
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </div>

                                    <div className="row" style={{ height: nameClass }}>

                                        <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                            <Form.Label><strong>
                                                Staff
                                                {/* {`${addLabel === "TMC" ? "Supervisor*" : "Staff*"}`}  */}

                                                &nbsp;Name*</strong></Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder={"Enter Staff Name"}
                                                value={rowData?.attdname}
                                                id="email"
                                                name={"attdname"}
                                                required={isEdit ? false : true}
                                                onChange={handleChange}
                                                pattern="[A-Za-z. ]+"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide valid name.
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                        <FormGroup className="text-start form-group col-12 col-sm-6">
                                            <Form.Label><strong>Designation*</strong></Form.Label>
                                            <Form.Select id="disabledSelect" value={SelectedDesignation} name={"designation"}
                                                onChange={handleChange}
                                            >
                                                {/* <option disabled value={0}>Select Designation</option> */}
                                                {
                                                    designationDropDown?.map((item, idx) => <option key={idx} value={item?.desmid}>{item?.dname}</option>
                                                    )
                                                }

                                            </Form.Select>
                                        </FormGroup>

                                    </div>

                                    <div className="row" style={{ height: nameClass }}>
                                        <FormGroup className="text-start form-group col-12 col-sm-6">
                                            <Form.Label><strong>Role*</strong></Form.Label>
                                            <Form.Select id="disabledSelect" value={SelectedRole} name={"roleid"}
                                                onChange={handleChange}
                                            >
                                                {/* <option disabled value={0} >Select Role</option> */}
                                                {
                                                    roleDropDown?.map((item, idx) => <option key={idx} value={item?.roleid}>{item?.rolename}</option>
                                                    )
                                                }

                                            </Form.Select>
                                        </FormGroup>

                                        <FormGroup className="text-start form-group col-12 col-sm-6">
                                            <Form.Label><strong>Email ID*</strong></Form.Label>
                                            <Form.Control
                                                className={`form-control`}
                                                placeholder={"Enter Email ID"}
                                                value={rowData?.email}
                                                id="email"
                                                type="email"
                                                name={"email"}
                                                onChange={handleChange}
                                                required={isEdit ? false : true}
                                                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </FormGroup>


                                    </div>

                                    <div className="row" style={{ height: nameClass }}>
                                        <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                            <Form.Label><strong>Mobile Number*</strong></Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder={"Enter Mobile Number"}
                                                value={rowData?.mobile}
                                                type="text"
                                                pattern="[0-9]+"
                                                minLength={10}
                                                maxLength={10}
                                                name={"mobile"}
                                                required={isEdit ? false : true}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide valid 10 digit mobile number.
                                            </Form.Control.Feedback>
                                        </FormGroup>

                                        <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                            <Form.Label><strong>Latitude*</strong></Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder={"Eg : 12.00000"}
                                                value={rowData?.latitude}
                                                id="latitude"
                                                name={"latitude"}
                                                required={isEdit ? false : true}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide valid latitude.
                                            </Form.Control.Feedback>
                                        </FormGroup>


                                    </div>

                                    <div className="row" style={{ height: nameClass === "201px" ? "45px" : nameClass }}>
                                        <FormGroup className="text-start form-group col-12 col-sm-6">
                                            <Form.Label><strong>Longitude*</strong></Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder={"Eg : 12.00000"}
                                                value={rowData?.longitude}
                                                id="attendeeid"
                                                name="longitude"
                                                onChange={handleChange}
                                                required={isEdit ? false : true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide valid longitude.
                                            </Form.Control.Feedback>
                                        </FormGroup>

                                    </div>
                                    <div className="row" style={{ height: nameClass === "201px" ? "45px" : nameClass }}>
                                        <FormGroup className="mt-3 text-sm form-group col-12 col-sm-6 d-flex justify-content-between">
                                            <Form.Label><strong>Is Nodal*</strong></Form.Label>
                                            <Form.Check className="mt-2"
                                                value="1"
                                                name="isnodal"
                                                type="radio"
                                                aria-label="radio 1"
                                                label="Yes"
                                                onChange={handleChange}
                                                checked={nodalOfficer === "1"}
                                            />
                                            <Form.Check className="mt-2"
                                                value="0"
                                                type="radio"
                                                name="isnodal"
                                                aria-label="radio 2"
                                                label="No"
                                                onChange={handleChange}
                                                checked={nodalOfficer === "0"}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide attendee code.
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </div>
                                    <div className="row" style={{ height: nameClass === "201px" ? "45px" : nameClass }}>
                                        <FormGroup className="text-sm form-group col-12 col-sm-6 d-flex justify-content-between">
                                            <Form.Label><strong>Gender*</strong></Form.Label>
                                            <Form.Check className="mt-2"
                                                value="M"
                                                name="gender"
                                                type="radio"
                                                aria-label="radio 1"
                                                label="Male"
                                                onChange={handleChange}
                                                checked={gender === "M"}
                                            />
                                            <Form.Check className="mt-2"
                                                value="F"
                                                type="radio"
                                                name="gender"
                                                aria-label="radio 2"
                                                label="Female"
                                                onChange={handleChange}
                                                checked={gender === "F"}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide attendee code.
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </div>

                                    <div className="row" style={{ height: "16vh" }}>
                                        {
                                            isEdit === true ?
                                                <FormGroup className="text-start form-group col-12 col-sm-10 ">
                                                    <Form.Label>Remarks*</Form.Label>
                                                    <Form.Control
                                                        className="form-control"
                                                        placeholder="Enter Remarks"
                                                        as="textarea" rows={2}
                                                        id="remarks"
                                                        name={"remarks"}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide remarks.
                                                    </Form.Control.Feedback>
                                                </FormGroup>
                                                :
                                                <></>
                                        }


                                        <Button
                                            disabled={false}
                                            type='submit'
                                            className="btn "
                                            style={{ position: "absolute", right: "16px", bottom: "6px" }}
                                        >
                                            {isEdit === false ? "ADD " : "Update "}

                                        </Button>
                                    </div>

                                </Form>

                            </Card>

                        </Modal.Body>
                    </>
            }
        </Modal>
    );
};
export default Staffmodal;
