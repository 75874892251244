import {
  ACTION_POST_HOLIDAY_LIST_REQ,
  ACTION_POST_HOLIDAY_ADD_REQ,
  ACTION_POST_HOLIDAY_UPADATE_REQ,
  ACTION_POST_HOLIDAY_DELETE_REQ,
  ACTION_POST_STATE_HOLIDAY_LIST_REQ,
  ACTION_POST_STATE_HOLIDAY_ADD_REQ,
  ACTION_POST_STATE_HOLIDAY_UPADATE_REQ,
  ACTION_POST_STATE_HOLIDAY_DELETE_REQ,
} from './SagaActionTypes'


export const holidayList = (payload) => {
  return {
    type: ACTION_POST_HOLIDAY_LIST_REQ,
    payload: payload,
  };
};

export const holidayAdd = (payload) => {
  return {
    type: ACTION_POST_HOLIDAY_ADD_REQ,
    payload: payload,
  };
};

export const holidayUpdate = (payload) => {
  return {
    type: ACTION_POST_HOLIDAY_UPADATE_REQ,
    payload: payload,
  };
};

export const holidayDelete = (payload) => {
  return {
    type: ACTION_POST_HOLIDAY_DELETE_REQ,
    payload: payload,
  };
};

export const stateholidayList = (payload) => {
  return {
    type: ACTION_POST_STATE_HOLIDAY_LIST_REQ,
    payload: payload,
  };
};

export const stateholidayAdd = (payload) => {
  return {
    type: ACTION_POST_STATE_HOLIDAY_ADD_REQ,
    payload: payload,
  };
};

export const stateholidayUpdate = (payload) => {
  return {
    type: ACTION_POST_STATE_HOLIDAY_UPADATE_REQ,
    payload: payload,
  };
};

export const stateholidayDelete = (payload) => {
  return {
    type: ACTION_POST_STATE_HOLIDAY_DELETE_REQ,
    payload: payload,
  };
};