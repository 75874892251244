import {
    ACTION_POST_AUDITLOGS_USER_REQ,
    ACTION_POST_AUDITLOGS_LIST_REQ,
} from "./SagaActionTypes";

export const auditLogUserAction = (payload) => ({
    type: ACTION_POST_AUDITLOGS_USER_REQ,
    payload,
});
export const auditLogListAction = (payload) => ({
    type: ACTION_POST_AUDITLOGS_LIST_REQ,
    payload,
});
