import React from "react";
import { Button, Modal } from "react-bootstrap";
/***
 * @param {boolean} showPopup- enable/disable popup
 * @param {boolean} setshowPopup- enable/disable popup
 * @param {text} titleText - placehoder after are u sure
 * @param {boolean} approvereject-helps decide color of titleText  
 * @function handleClick(approvereject,data)- call back function 
 * @param {text:optional} remarkDeatils -helps giving reason for decision
 * @param {text:optional} defaultText -replaces Question above yes no button
 */
const ImageModal = (props) => {
    const { showPopup,
        // setShowPopup,
        // handleClick,
        // approvereject,
        // data,
        // customText,
        // titleText,
        // setRemarkDetails,
        img1,           
        img2,
        handleClose
         } = props

    
    return (
        // <Modal
        //     // size={remarkDetails !== undefined ? "lg center" : "sm center"}
        //     backdrop="true"
        //     size={"lg center"}
        //     show={showPopup}
        //     aria-labelledby="contained-modal-title-vcenter"
        //     centered
        //     onHide={handleClose}
        //     keyboard={true}
        // >
        // <Modal.Header closeButton>
        //   <Modal.Title>Modal heading</Modal.Title>
        // </Modal.Header>
        // <div style={{
        //     margin: "20px",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center"
        // }}>
            // <img src={img1} alt="thumb" width={300} height={300}  style={{ objectFit: "contain"}} />
            // <img src={img2} alt="dthumb" width={300} height={300} style={{ objectFit: "contain"}}  />

        // </div>
        // </Modal>
        <Modal
        size="lg"
        show={showPopup}
        onHide={handleClose}
        centered

        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title>Image</Modal.Title>
          <Button
            variant=""
            className="btn btn-close ms-auto"
            onClick={() => { handleClose() }}
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
            <img src={img1} alt="thumb" width={300} height={300}  style={{ objectFit: "contain"}} />
            <img src={img2} alt="dthumb" width={300} height={300} style={{ objectFit: "contain"}}  />
        </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary" >
            Save Changes
          </Button> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
export default ImageModal;