import React from 'react'
import ReportTable from '../../components/Table'
import { Breadcrumb, Card, Col, Row, Table } from 'react-bootstrap'
import AttendReportPresenter from './AttendReportPresenter'
import { AbsentSvg, EarlyLogOutSvg, EnrolledSvg, LateLoginSvg, PresentSvg } from '../../assets/svgs/UserSvg'
import { LeaveCountSvg, PunchSvg, UniquePunchSvg } from '../../assets/svgs/LeavesSvg'


const AttendanceReports = () => {
  const { Breadcrumbs, attendanceData,
    handleClick,
    attendanceTableData,
    selectedDate,
    onSelectDate } = AttendReportPresenter()

  let cardData = [
    {
      img: <EnrolledSvg />,
      name: "Enrolled",
      count: attendanceData?.enrolled,

    },
    {
      img: <PresentSvg />,
      name: "Present",
      count: attendanceData?.present,
    },
    {
      img: <AbsentSvg />,
      name: "Absent",
      count: attendanceData?.absent,

    },
    {
      img: <PunchSvg />,
      name: "Punches",
      count: attendanceData?.punches,

    },
    {
      img: <UniquePunchSvg />,
      name: "Unique Punch",
      count: attendanceData?.unique_punches,

    },
    {
      img: <LeaveCountSvg />,
      name: "Leave Count",
      count: attendanceData?.leave_count,

    },
    {
      img: <LateLoginSvg />,
      name: "Late Login Count",
      count: attendanceData?.latelogin_count,

    },
    {
      img: <EarlyLogOutSvg />,
      name: "Early Logout Count",
      count: attendanceData?.earlylogout_count,
    },
  ]

  return (
    <div>
      <div className="main justify-content-between">
        <Row className="row-sm mt-4">
          <Col xl={12} lg={12} md={12} sm={12}>
            <Card>
              {/* <Card.Body className="iconfont text-start d-flex"> */}
              {
                Breadcrumbs && Breadcrumbs()
              }
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
        <Row className="row-sm">
          {
            cardData.map((item, idx) => {
              return (
              <Col xl={3} lg={6} md={6} sm={12}>
                <Card>
                  <Card.Body className="iconfont text-start d-flex">
                    <div className="media mt-0">
                      {item.img}
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <div className="mt-0 ms-3">
                            <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                              {item.name}
                            </h5>
                            <p className="mb-0 tx-12 text-muted">
                              {item.count}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </Col>
              )
            })
          }
        </Row>

        {attendanceTableData?.length > 0 ?
          <ReportTable
            title={'Attendance Report'}
            clickableColumns={[[0, 1], handleClick]}
            StyledColumns={[
              [0, 1],
              [" underline", "underline"],
            ]}
            tableData={attendanceTableData}
            selectedDate={selectedDate}
            onSelectDate={onSelectDate}
            Breadcrumbs={Breadcrumbs}
            fileName={"Attendance Report"}
          /> : <></>
        }
      </div>

    </div>
  )
}

export default AttendanceReports