import React, { useMemo, useRef } from "react";
import { Button, Card, Form, FormGroup, InputGroup, Modal } from "react-bootstrap";
import ReactSelect from "react-select";


const AddNotificationmodal = (props) => {
    const {
        showPopup,
        handleChange,
        isEdit,
        validated,
        setValidated,
        showMessageModal,
        whatsAppTemplates,
        selectedWhatsAppMessage,
        OnSelectMessageTemplate,
        modalcallType,
        staffList,
        selectedStaffList,
        setSelectedStaffList,
        handleMessage,
        messageInput,
        closeModal,
        callingApis,
        rowData,
        smsTemplates,
        
    } = props;


    const valueRef = useRef(selectedStaffList);
    valueRef.current = selectedStaffList;

    const selectAllOption = {
        value: "<SELECT_ALL>",
        label: "All Users"
    };

    const isSelectAllSelected = () =>
        valueRef.current.length === staffList.length;

    const isOptionSelected = option =>
        valueRef.current.some(({ value }) => value === option.value) ||
        isSelectAllSelected();

    const getOptions = () => [selectAllOption, ...staffList];

    const getValue = () =>
        isSelectAllSelected() ? [selectAllOption] : selectedStaffList;

    const onChange = (newValue, actionMeta) => {
        const { action, option, removedValue } = actionMeta;

        if (action === "select-option" && option.value === selectAllOption.value) {
            setSelectedStaffList(staffList, actionMeta);
        } else if (
            (action === "deselect-option" &&
                option.value === selectAllOption.value) ||
            (action === "remove-value" &&
                removedValue.value === selectAllOption.value)
        ) {
            setSelectedStaffList([], actionMeta);
        } else if (
            actionMeta.action === "deselect-option" &&
            isSelectAllSelected()
        ) {
            setSelectedStaffList(
                staffList.filter(({ value }) => value !== option.value),
                actionMeta
            );
        } else {
            setSelectedStaffList(newValue || [], actionMeta);
        }
    };

    let reg = /{{\d+}}/;
    const maping = () => {
        const arr = selectedWhatsAppMessage.replaceAll(/{{\d+}}/g, "_").split("_")
        return arr.map((i, idx) => {
            // if (arr?.length - 1 === idx)
            //     return <></>
            return <>
                <p style={{ margine: "0!important", padding: "1%", display: "inline" }}>
                    {i[0].toUpperCase() + i.substring(1).toLowerCase()}
                    {/* {i} */}
                </p>
                {
                    arr?.length - 1 === idx ?
                        <></> :
                        <input class="form-control form-control d-inline mt-2"
                            placeholder="Enter Text" name={`text${idx}`}
                            onChange={handleMessage}
                            type="text"
                            style={{ width: "fit-content" }}
                            value={messageInput[`text${idx}`]}
                        />

                }
            </>
        })
    }

    const WhatsappNotification = useMemo(() => maping(), [selectedWhatsAppMessage, messageInput])

    return (
        <>
            {
                modalcallType === "send" &&
                <Modal
                    show={showPopup}
                    size="lg"
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered
                    style={{ zIndex: "9991" }}
                >

                    <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
                        <Modal.Title className="text-center">
                            Send Notification
                        </Modal.Title>
                        <Button
                            variant=""
                            className="btn btn-close"
                            onClick={() => {
                                closeModal()
                            }}
                        >
                            x
                        </Button>
                    </Modal.Header>
                    <Modal.Body className="pt-1 ">
                        <Card className="mt-3">
                            <Form noValidate
                                className="needs-validation"
                                onSubmit={(e) => {
                                    callingApis(e, "pushNotification")
                                }}
                                validated={validated}
                            >

                                <div className="row" >
                                    <FormGroup className="text-start form-group col-12 col-sm-4">
                                        <Form.Label>Send Push Notification</Form.Label>

                                    </FormGroup>
                                    <InputGroup className="input-group date-pic mb-2" >
                                        <ReactSelect
                                            isOptionSelected={isOptionSelected}
                                            options={getOptions()}
                                            value={getValue()}
                                            onChange={onChange}
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={false}
                                            isMulti
                                        />
                                    </InputGroup>
                                </div>

                                <div className="row flex-row-reverse" >
                                    <Button
                                        disabled={false}
                                        type='submit'
                                        className="btn me-3"
                                    >
                                        Send
                                    </Button>
                                </div>

                            </Form>

                        </Card>

                    </Modal.Body>

                </Modal>
            }
            {
                modalcallType === "edit" || modalcallType === "add" ?
                    <Modal
                        show={showPopup}
                        size="lg"
                        aria-labelledby="example-modal-sizes-title-lg"
                        centered
                        style={{ zIndex: "9991" }}
                    >

                        <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
                            <Modal.Title className="text-center">
                                {isEdit ? "Edit" : "ADD"} Notification
                            </Modal.Title>
                            <Button
                                variant=""
                                className="btn btn-close"
                                onClick={() => {
                                    closeModal()
                                }}
                            >
                                x
                            </Button>
                        </Modal.Header>
                        <Modal.Body className="pt-1 ">
                            <Card className="mt-3">
                                <Form noValidate
                                    className="needs-validation"
                                    onSubmit={(e) => {
                                        callingApis(e, modalcallType)
                                        setValidated(true);
                                    }}
                                    validated={validated}
                                >

                                    <div className="row" >
                                        <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder="Enter title"
                                                value={rowData.title}
                                                type="text"
                                                name={"title"}
                                                onChange={handleChange}
                                                // maxLength={20}
                                                required={isEdit ? false : true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide title.
                                            </Form.Control.Feedback>
                                        </FormGroup>

                                        <FormGroup className={"text-sm form-group col-12 col-sm-6"}>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                placeholder="Enter message"
                                                value={rowData.message}
                                                type="text"
                                                name={"message"}
                                                onChange={handleChange}
                                                // maxLength={20}
                                                required={isEdit ? false : true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide message .
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </div>

                                    <div className="row flex-row-reverse" >
                                        <Button
                                            disabled={false}
                                            type='submit'
                                            className="btn me-3"
                                        >
                                            {isEdit ? "Edit" : "ADD"}
                                        </Button>
                                    </div>

                                </Form>

                            </Card>

                        </Modal.Body>

                    </Modal>
                    : <></>
            }


            {
                modalcallType === "whatsApp" || modalcallType === "sms" ?
                    <Modal
                        show={showPopup}
                        size="lg"
                        aria-labelledby="example-modal-sizes-title-lg"
                        centered
                        style={{ zIndex: "9991" }}
                    >

                        <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
                            <Modal.Title className="text-center">
                                {modalcallType === "whatsApp" ? "WhatsApp" : "SMS"} Notification
                            </Modal.Title>
                            <Button
                                variant=""
                                className="btn btn-close"
                                onClick={() => {
                                    closeModal()
                                }}
                            >
                                x
                            </Button>
                        </Modal.Header>
                        <Modal.Body className="pt-1 ">
                            <Card className="mt-3">
                                <Form noValidate
                                    className="needs-validation"
                                    onSubmit={(e) => {
                                        callingApis(e, modalcallType)
                                    }}
                                    validated={validated}
                                >
                                    <Form.Label>Select Template</Form.Label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="checkbox-order ps-0 overflow-y-auto" style={{ maxHeight: "200px" }}>
                                                {
                                                    smsTemplates && smsTemplates?.map((item) => {
                                                        return <li className="d-flex gap-3">
                                                            <input
                                                                type="radio"
                                                                className=""
                                                                onChange={(e) => OnSelectMessageTemplate(item)}
                                                                id={`issue1${item?.nid}`}
                                                                name="issue1"
                                                                value={selectedWhatsAppMessage}
                                                            />
                                                            <label htmlFor={`issue1${item?.nid}`} className="connection-text">
                                                                {" "}
                                                                {/* {item?.message} */}
                                                                {item?.message[0].toUpperCase() + item?.message.substring(1).toLowerCase().replaceAll(/{{\d+}}/g, "____")}
                                                            </label>
                                                        </li>
                                                    }

                                                    )
                                                }
                                                {
                                                    whatsAppTemplates && whatsAppTemplates?.map((item) => {
                                                        return <li className="d-flex gap-3">
                                                            <input
                                                                type="radio"
                                                                className=""
                                                                onChange={(e) => OnSelectMessageTemplate(item)}
                                                                id={`issue1${item?.nid}`}
                                                                name="issue1"
                                                                value={selectedWhatsAppMessage}
                                                            />
                                                            <label htmlFor={`issue1${item?.nid}`} className="connection-text">
                                                                {" "}
                                                                {/* {item?.message} */}
                                                                {(item?.message[0].toUpperCase() + item?.message.substring(1).toLowerCase()).replaceAll(/{{\d+}}/g, "____")}
                                                            </label>
                                                        </li>
                                                    }

                                                    )
                                                }
                                            </ul>
                                        </div>

                                    </div>
                                    <div className="row">

                                        <FormGroup className={"text-sm form-group col-12 col-sm-12 h-25"}>
                                            <Form.Label>Message</Form.Label>
                                            <div style={{ display: "flex", alignItems: "baseline" }}>
                                                <div>
                                                    {WhatsappNotification}
                                                    {/* {WhatsappNotification.toUpperCase()+WhatsappNotification.substring(1).toLowerCase()} */}

                                                </div>
                                            </div>
                                        </FormGroup>

                                    </div>
                                    <div className="row">
                                        <FormGroup className="text-start form-group col-12 col-sm-12">
                                            <Form.Label>Select Attendee </Form.Label>
                                            <InputGroup className="input-group date-pic mt-2" >
                                                <ReactSelect
                                                    isOptionSelected={isOptionSelected}
                                                    options={getOptions()}
                                                    value={getValue()}
                                                    onChange={onChange}
                                                    hideSelectedOptions={false}
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </div>

                                    <div className="row flex-row-reverse" >
                                        <Button
                                            disabled={false}
                                            type='submit'
                                            className="btn me-3"
                                        >
                                            Send
                                        </Button>
                                    </div>

                                </Form>

                            </Card>

                        </Modal.Body>

                    </Modal>
                    : <></>
            }
        </>
    );
};
export default AddNotificationmodal;
