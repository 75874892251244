import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import {
  notificationAddAction,
  notificationDeleteAction,
  notificationListAction,
  notificationUpdateAction,
  pushNotificationAction,
  sendSmsAction,
  sendWhatsAppNotificationAction,
  smsTemplatesNotificationAction,
  whatsAppNotificationAction
} from '../Store/SagaActions/NotificationSagaAction';
import { hierarchywiseStaffListAction } from '../Store/SagaActions/TableDetailSagaAction';
import LoaderComp from '../components/LoaderComp/LoaderComp';
import ReportTable from '../components/Table';
import AddUpdateNotificationmodal from '../feature/Notification/AddNotificationmodal';
import AreUSureModal from '../components/Modals/Duplicates';

const NotificationMangement = () => {

  const dispatch = useDispatch()
  const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;
  const homeOrgid = JSON.parse(sessionStorage.getItem("authkey"))?.orgid ?? undefined;
  const [showPopup, setShowPopup] = useState(false); // show modal
  const [showMessageModal, setShowMessageModal] = useState(false); // show modal
  const [notificationTableData, setNotificationTableData] = useState([])
  const [isEdit, setisEdit] = useState(false)
  const [rowData, setRowData] = useState({})
  const [validated, setValidated] = useState(false); //for form validation
  const [whatsAppTemplates, setWhatsAppTemplates] = useState([])
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [selectedWhatsAppMessage, setSelectedWhatsAppMessage] = useState("0")
  const [modalcallType, setModalCallType] = useState("")
  const [staffList, setStaffList] = useState([])
  const [selectedStaffList, setSelectedStaffList] = useState([])
  const [messageInput, setMessageInput] = useState({})
  const [messageTempalteId, setMessageTemplateId] = useState("")
  const [AreuSurePopup, setAreuSurePopup] = useState(false);
  const [deleteId, setDeleteId] = useState("")
  const [notificationFullTableData, setNotificationFullTableData] = useState([])


  const actionButton = () => {
    return (
      <div>
        <div>
          <Button variant="" className="btn btn-success-light m-1"  >
            Send
          </Button>
          <Button variant="" className="btn btn-warning-light m-1 "  >
            Edit
          </Button>
          <Button variant="" className="btn btn-danger-light m-1">
            Delete
          </Button>
        </div>
      </div>
    )
  }
  const OnSelectMessageTemplate = (e) => {
    setMessageTemplateId(e?.nid)
    setSelectedWhatsAppMessage(e?.message)
  }

  const openModal = (type) => {
    setModalCallType(type)
    setValidated(false)
    setShowPopup(true)
  }
  const openWhatsAppModal = () => {
    callingstaffList()
    dispatch(
      whatsAppNotificationAction({
        callback: (data) => {
          setWhatsAppTemplates(data)
        }
      })
    )
    setShowMessageModal(true)
    setShowPopup(true)
  }
  const openSmsModal = () => {
    callingstaffList()
    dispatch(
      smsTemplatesNotificationAction({
        callback: (data) => {
          setSmsTemplates(data)
        }
      })
    )
    setShowMessageModal(true)
    setShowPopup(true)
  }

  const openMessageModal = (type) => {
    switch (type) {
      case 'whatsApp':
        setModalCallType("whatsApp")
        openWhatsAppModal()
        return
      case 'sms':
        setModalCallType("sms")
        openSmsModal()
        return
    }
  }
  const callingApis = (e, type) => {
    let model, datamapped
    e.preventDefault()
    const form = e.currentTarget;
    switch (type) {
      case "edit":
        if (form.checkValidity()) {
          setisEdit(false)
          model = {
            "nid": rowData?.nid,
            "title": rowData?.title,
            "message": rowData?.message
          }
          dispatch(
            notificationUpdateAction({
              model,
              callback: (data) => {
                if (data?.responsecode === "200") {
                  getNotificationList()
                  toast.success(data?.responsedesc)
                  // setRowData({})
                  // setShowPopup(false)
                  setValidated(false)
                  closeModal()
                }
                else toast.error(data?.responsedesc + " Please try again")
              }
            })
          )
        }
        return
      case "add":
        if (form.checkValidity()) {
          model = rowData
          dispatch(
            notificationAddAction({
              model,
              callback: (data) => {
                if (data?.responsecode === "200") {
                  getNotificationList()
                  toast.success(data?.responsedesc)
                  // setRowData({})
                  // setShowPopup(false)
                  // setValidated(false)
                  closeModal()
                }
                else toast.error(data?.responsedesc + " Please try again")
              }
            })
          )
        }
        return
      case "pushNotification":
        datamapped = selectedStaffList.map((i) => {
          const { value } = i
          const temp = value
          return temp;
        })

        if (form.checkValidity()) {
          model = {
            "nid": rowData?.nid,
            "sendtoall": datamapped.length === staffList.length ? "1" : "0",
            "aids": datamapped.length === staffList.length ? [] : datamapped
          }
          dispatch(
            pushNotificationAction({
              model,
              callback: (data) => {
                toast.success(data?.responsedesc)
                closeModal()
                // setRowData({})
                // setShowPopup(false)
              }
            })
          )
        }
        return
      case "whatsApp":
        datamapped = selectedStaffList.map((i, idx) => {
          const { value } = i
          const temp = value
          return temp;
        })
        const vals = Object.keys(messageInput).map(key => messageInput[key]);
        if (form.checkValidity()) {

          model = {
            "nid": messageTempalteId,
            "sendtoall": datamapped.length === staffList.length ? "1" : "0",
            "parameters": vals,
            "aids": datamapped.length === staffList.length ? [] : datamapped
          }
          dispatch(
            sendWhatsAppNotificationAction({
              model,
              callback: (data) => {
                if (data?.responsecode === "200") {
                  toast.success(data?.responsedesc)
                  closeModal()
                }
                else if (data?.responsecode === "300") {
                  toast.error(data?.responsedesc)
                }
                // setWhatsAppTemplates([])
                // setSelectedWhatsAppMessage("0")
                // setMessageInput({})
                // setSelectedStaffList([])
                // setRowData({})
                // setShowPopup(false)
              }
            })
          )
        }
        return

      case "sms":
        datamapped = selectedStaffList.map((i, idx) => {
          const { value } = i
          const temp = value
          return temp;
        })
        let valss = Object.keys(messageInput).map(key => messageInput[key]);
        if (form.checkValidity()) {
          model = {
            "nid": messageTempalteId,
            "sendtoall": datamapped.length === staffList.length ? "1" : "0",
            "parameters": valss,
            "aids": datamapped.length === staffList.length ? [] : datamapped
          }
          dispatch(
            sendSmsAction({
              model,
              callback: (data) => {
                if (data?.responsecode === "200") {
                  toast.success(data?.responsedesc)
                }
                else if (data?.responsecode === "300") {
                  toast.error(data?.responsedesc)
                }
                setSelectedWhatsAppMessage("0")
                setMessageInput({})
                setSelectedStaffList([])
                setRowData({})
                setShowPopup(false)
              }
            })
          )
        }
        return

      default:
        return
    }

  }

  const deleteNotification = (id) => {
    let model = {
      nid: deleteId
    }
    dispatch(
      notificationDeleteAction({
        model,
        callback: (data) => {
          toast.success(data?.responsedesc)
          getNotificationList()
          setAreuSurePopup(false)
        }
      })
    )
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setRowData({...rowData, [name]: value});
  }


  const handleMessage = (e) => {
    setMessageInput({ ...messageInput, [e.target.name]: e.target.value })
  }
  const notificationList = (data) => {
    if (data.length === 0) toast.error("No data found")
    const datamapped = data.map((i, idx) => {
      const { nid, title, message } = i
      const temp = {
        "S no": idx + 1,
        // "Id": nid,
        "Title": title,
        "Description": message,
        "Action": actionButton(),
      }
      return temp;
    })
    setNotificationFullTableData(data)
    setNotificationTableData(datamapped)
  }

  const getNotificationList = () => {
    if (userRole === "PRINCIPAL") {
      dispatch(
        notificationListAction({
          callback: (data) => {
            notificationList(data)
          }
        })
      )
    }
  }
  const settingStaffList = (data) => {
    const datamapped = data.map((i, idx) => {
      const { attdname, aid, } = i
      const temp = {
        "label": attdname,
        "value": aid,
      }
      return temp;
    })
    setStaffList(datamapped)
  }
  const callingstaffList = () => {
    if (userRole === "PRINCIPAL") {
      let model = { "orgid": homeOrgid }
      dispatch(
        hierarchywiseStaffListAction({
          model,
          callback: (data) => {
            settingStaffList(data?.details)
          }
        })
      )
    }
  }
  const handleClick = (e, data, row, header, col) => {
    setRowData(notificationFullTableData[row])
    let buttonType = e.target.outerText
    switch (buttonType) {
      case "Edit":
        setModalCallType("edit")
        setisEdit(true)
        setShowPopup(true)
        return
      case "Delete":
        setModalCallType("delete")
        setDeleteId(notificationFullTableData[row]?.nid)
        setAreuSurePopup(true)
        return
      case "Send":
        setModalCallType("send")
        callingstaffList()
        setShowPopup(true)
        return
      default:
        return
    }
  }
  const closeModal = () => {
    setSelectedWhatsAppMessage("0")
    setRowData({})
    setisEdit(false)
    setMessageInput({})
    setSelectedStaffList([])
    setShowMessageModal(false)
    setShowPopup(false);
    setWhatsAppTemplates([])
    setSmsTemplates([])
    setValidated(false)
  }

  useEffect(() => {
    getNotificationList()
  }, [])


  return (
    <div className='mt-3'>
      <LoaderComp />
      <AreUSureModal
        showPopup={AreuSurePopup}
        setShowPopup={setAreuSurePopup}
        customText={`Are You Sure you want to delete ?`}
        handleClick={deleteNotification}
        style={{
          zIndex: "9991"
        }}
      />
      <AddUpdateNotificationmodal
        showPopup={showPopup}
        handleChange={handleChange}
        isEdit={isEdit}
        validated={validated}
        setValidated={setValidated}
        showMessageModal={showMessageModal}
        whatsAppTemplates={whatsAppTemplates}
        smsTemplates={smsTemplates}
        selectedWhatsAppMessage={selectedWhatsAppMessage}
        OnSelectMessageTemplate={OnSelectMessageTemplate}
        modalcallType={modalcallType}
        staffList={staffList}
        setSelectedStaffList={setSelectedStaffList}
        selectedStaffList={selectedStaffList}
        handleMessage={handleMessage}
        messageInput={messageInput}
        closeModal={closeModal}
        callingApis={callingApis}
        rowData={rowData}

      />
      {notificationTableData.length > 0 ?
        <div className="main justify-content-between">
          <ReportTable
            title={'Notifications'}
            tableData={notificationTableData}
            openModal={openModal}
            clickableColumns={[[3], handleClick]}
            fileName={"Notification"}
            buttonText={"Add Notification"}
            openMessageModal={openMessageModal}
          />
        </div>
        :
        (notificationTableData.length === 0) ?
          <Card>
            <div className="d-flex mt-4 me-3 justify-content-end">
              <Button className="mb-2 " onClick={() => openModal("add")}>Add Notification</Button>
            </div>
          </Card> : <></>
      }

    </div>
  )
}
export default NotificationMangement