import { all } from 'redux-saga/effects';
import DepartmentWatcherSaga from './DepartmentSaga';
import AuthWatcherSaga from './AuthWatcherSaga';
import DepartmentDeatilsWatcherSaga from './DepartmentDetailsSaga';
import AttaindenceWatcherSaga from './AtendenceDetailsSaga';
import TableDetailsWatcherSaga from './TableDetailsSaga';
import PrincipalWatcherSaga from './PrincipalWatcherSaga'
import AttendeeMangementSaga from './AttendeeMangementSaga';
import HolidayWatcherSaga from './HolidaySaga';
import GrivenceWatcherSaga from './GrivenceWatcherSaga';
import NotificationWatcherSaga from './NotificationSaga';
import AuditLogsWatcherSaga from './AuditLogsSaga';


export default function* rootSage() {
    yield all([ 
        DepartmentWatcherSaga(),
        AuthWatcherSaga(),
        DepartmentDeatilsWatcherSaga(),
        AttaindenceWatcherSaga(),
        TableDetailsWatcherSaga(),
        PrincipalWatcherSaga(),
        AttendeeMangementSaga(),
        HolidayWatcherSaga(),
        GrivenceWatcherSaga(),
        NotificationWatcherSaga(),
        AuditLogsWatcherSaga(),
    ])
}
