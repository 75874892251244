import React from 'react'
import ReportTable from '../../components/Table'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Row, Table } from 'react-bootstrap'
import AuditLogPresenter from './AuditLogPresenter'
import Calendar from "../../components/Calendar"
import LoaderComp from '../../components/LoaderComp/LoaderComp'


const AuidtLogs = () => {
  const { auditLogsUser, selectedFromDate, selectedToDate,
    onSelectFromDate, onSelectToDate, callingAuditLogsListData,
    auditTableData, selectedUser,onSelectUser } = AuditLogPresenter()
  return (
    <div>
      <LoaderComp/>
      <Card className="col-12"
        style={{ marginTop: "1rem" }}>
        <Row className="">
          <FormGroup className="text-start form-group col-12 col-sm-3">
            <Form.Label>User*</Form.Label>
            <Form.Select id="disabledSelect" value={selectedUser} onChange={onSelectUser}
            >
              <option value={0}>All User</option>
              {
                auditLogsUser?.map((item, idx) =>{
                  return(
                  <option key={idx} value={item?.value}>{item?.label}</option>

                  )
                }
                
                )
              }
            </Form.Select>
          </FormGroup>
          <FormGroup className="text-start form-group col-12 col-sm-3">
            <Form.Label>From</Form.Label>
            <div className="mb-4" style={{ "marginRight": "-50px" }}>
              <Calendar
                selectedDate={selectedFromDate}
                onSelectDate={onSelectFromDate}
              />
            </div>
          </FormGroup>
          <FormGroup className="text-start form-group col-12 col-sm-3">
            <Form.Label>To</Form.Label>
            <div className="mb-4" >
              <Calendar
                selectedDate={selectedToDate}
                onSelectDate={onSelectToDate}
              />
            </div>
          </FormGroup>
          <FormGroup className="text-start form-group col-12 col-sm-3 mt-3">
            <div className="mb-4 mt-3" >
              <Button type="button"
                onClick={() => callingAuditLogsListData()}
              >Submit</Button>
            </div>
          </FormGroup>
        </Row>
      </Card>
      {auditTableData.length > 0 ?
        <ReportTable
          title={'Audit Logs'}
          tableData={auditTableData}
          fileName={`Audit Logs`}
          StyledColumns={[[4], ["wrap"]]}
        />
        : <></>}
    </div>
  )
}

export default AuidtLogs