import {
  ACTION_POST_GRIVENCE_PIECHART_REQ,
  ACTION_POST_GRIVENCE_ALL_REQ,
  ACTION_POST_GRIVENCE_TICKET_HISTORY_REQ,
  ACTION_POST_GRIVENCE_TICKET_ASSIGN_DROPDOWN_REQ,
  ACTION_POST_GRIVENCE_TICKET_ASSIGN_REQ,
  ACTION_POST_GRIVENCE_INPROGRESS_REQ,
  ACTION_POST_GRIVENCE_PENDING_REQ,
  ACTION_POST_GRIVENCE_CLOSED_ASSIGN_REQ,
  ACTION_POST_GRIVENCE_ASSIGN_TO_ME_REQ,
  ACTION_POST_GRIVENCE_ASSIGN_PRINCIPAL_REQ
} from "./SagaActionTypes";

export const grivencePiechart = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_PIECHART_REQ,
    payload: payload,
  };
};

export const grivenceAll = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_ALL_REQ,
    payload: payload,
  };
};

export const grivencetickethistory = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_TICKET_HISTORY_REQ,
    payload: payload,
  };
};

export const grivenceticketassigndropdown = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_TICKET_ASSIGN_DROPDOWN_REQ,
    payload: payload,
  };
};

export const grivenceticketassign = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_TICKET_ASSIGN_REQ,
    payload: payload,
  };
};

export const grivenceticketinprogress = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_INPROGRESS_REQ,
    payload: payload,
  };
};

export const grivenceticketpending = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_PENDING_REQ,
    payload: payload,
  };
};

export const grivenceticketclosed = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_CLOSED_ASSIGN_REQ,
    payload: payload,
  };
};

export const grivenceassignedtome = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_ASSIGN_TO_ME_REQ,
    payload: payload,
  };
};

export const grivenceprincipal = (payload) => {
  return {
    type: ACTION_POST_GRIVENCE_ASSIGN_PRINCIPAL_REQ,
    payload: payload,
  };
};
