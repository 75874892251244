export const pathName = {
    login: `/login`,
    dashboard: `/dashboard`,
    pricipalDashboard: `/dashboard/report`,
    holiday: `/Holiday`,
    grivences: `/grivences`,
    grivencesHistory: `/grivenceshistory`,
    report:`/reports`,
    changePassword:`/changepassword`,
    download:`/download`,
    attendeeDetails:`/attendeeDetails`,
    leave:`/leave`,
    attendanceview:`/attendanceview`,
    notificationManagement:`/notification_management`,
    notificationLogs:`/notification_logs`,
    notificationList:`/notification_list`,
    auditlogs:`/auditlogs`,
    privacyPolicy:`/privacy-policy`,
    detailedreports:`/detailedreports`,
    detail:`/detail`,
    collegeReports:`/college-reports`,
    duplicate:`/duplicate`,
    duplicatehistory:`/duplicatehistory`,
    attendeeclass:`/attendeeclass`,
    attendeestaff:`/attendeestaff`,
    attendeeorganization:`/attendeeorganization`,
    attendeestudent:`/attendeestudent`,
    error404:`/pages/Authentication/404error`,
    error500:`/pages/Authentication/500error`,
    switcherApp:`/pages/switcher/switcher-1`,
    help:`/help`,
    helpPage:`/dashboard/help`,
}
