import React , { useEffect, useState } from 'react'
import {principalDeuplicateHistory } from '../../Store/SagaActions/PrincipalSagaActions';
import { useDispatch } from "react-redux";
import ReportTable from '../../components/Table';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import LoaderComp from '../../components/LoaderComp/LoaderComp';

function DuplicatesHistory() {
    const dispatch = useDispatch();
    let orgid = JSON.parse(sessionStorage.getItem("authkey")).orgid;
    useEffect(() => {
        let model = {"groupid":orgid}
        dispatch(principalDeuplicateHistory({model,callback:(data) => dupliactehistory(data)}))
    }, [])
  
  const [HistoryList, setHistoryList] = useState([])
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectFilteroption, setSelectFilteroption] = useState({ value: 0, label: "ALL", short: "ALL" });
  let labels = JSON.parse(sessionStorage.getItem("labels"));

  const dupliactehistory = (data) => { 
    const mappedData = data?.deduphistorydetails?.map((i, idx) => { 
        return ({
          [`${labels?.institute}/ ${labels?.class}`]: i.college_or_classname,
          "att id": i.attendeeid,
          "att name": i.attendeename,
          "designation": i.designation,
          [`dup ${labels?.institute}/ ${labels?.class}`]: i.dcollege_or_classname,
          "dup attid": i.dattendeeid,
          "dup attname": i.dattendeename,
          "dup designation": i.ddesignation,
          "Status": i.status==="1"?"Approved":"Rejected",
          "createdate": moment(i?.createdon, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY"),
          "actiondate": moment(i?.approveddate, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY"),
      })

    })
    const datamappedFilter = data?.deduphistorydetails?.map(i => (i.status==="1"?"Approved":"Rejected"));
      const option = [... new Set(datamappedFilter)]
      const options = option.map((i, idx) => {
        return ({
          value: idx + 1,
          label: i,
          short: i
        })
      });
      setFilterOptions([{ value: 0, label: "ALL", short: "ALL" }, ...options]);
      setHistoryList(mappedData)
      
    }
  const onSelectFilteropion = (e) => {
    setSelectFilteroption(e);
  }
  
  return (
//     <Card className="text-primary"
//     style={{ marginTop: "2rem", marginBottom: "2rem", padding: "1rem" }}

// > Coming Soon</Card>
    <div>
      <LoaderComp/>
      {HistoryList.length > 0 &&
        <ReportTable
          title={`List of Duplicates History`}
          tableData={HistoryList}
          filterOptions={filterOptions}
          selectFilteroption={selectFilteroption}
          onSelectFilteropion={onSelectFilteropion}
          fileName={`Duplicate History`}
        //   type={type}
        />}

    </div>
  )
}

export default DuplicatesHistory