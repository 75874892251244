export const RepoortTypes = {
  REPORTTYPE_DISTRICTS: "1",
  REPORTTYPE_MANDALS: "2",
  REPORTTYPE_COLLEGES: "3",
  REPORTTYPE_COLLEGES_DIRECT: "4",
  REPORTTYPE_COURSES: "5",
  REPORTTYPE_STUDENTS: "null",
};

export const DistrictCollegeTypes = {
  DISTRICT_TYPE: "1",
  COLLEGE_TYPE: "4",
};

export const SelectFlowName = {
  DISTRICT: "District",
  MANDAL: "Mandal",
  COLLEGE: "College",
  COURSE: "Course",
  STUDENT: "Student",
  COLLEGE_DIRECT: "CollegeDirect",
};

export const selectedFlow = (type) => {
  switch (type) {
    case SelectFlowName.DISTRICT:
      return {
        flowName: SelectFlowName.DISTRICT,
        nextFlowName: SelectFlowName.COLLEGE,
        selectedRptId: RepoortTypes.REPORTTYPE_MANDALS,
      };
    case SelectFlowName.COLLEGE:
      return {
        flowName: SelectFlowName.COLLEGE,
        nextFlowName: SelectFlowName.COURSE,
        selectedRptId: RepoortTypes.REPORTTYPE_COURSES,
      };

    case SelectFlowName.COURSE:
      return {
        flowName: SelectFlowName.COURSE,
        nextFlowName: SelectFlowName.STUDENT,
        selectedRptId: RepoortTypes.REPORTTYPE_STUDENTS,
      };

    default:
      return {
        flowName: "",
        nextFlowName: "",
        selectedRptId: "",
      };
  }
};


export const options1 = [
  { value: "1", label: "Districts" },
  { value: "4", label: "College" },
];

export const options3 = [
  { value: "2", label: " Enrollment" },
  { value: "1", label: " Attendance" },
];

export const options4 = [
  { value: "0", label: "ALL",short:"ALL" },
  { value: "1", label: "Present", short: "P" },
  { value: "2", label: "Absent", short: "A" },
  { value: "3", label: "Enrolled", short:"Enrolled"},
  { value: "4", label: "Not Enrolled", short: "NE" },
  { value: "5", label: "On Leave", short: "OL" },
  // { value: "6", label: "Sunday", short: "SU" },
  // { value: "7", label: "Second Saturday", short: "SS" },
  { value: "6", label: "Authorised Absent", short: "AA" },
  // { value: "9", label: "Holiday", short: "H" },
];
export const studentOptions = [
  { value: "0", label: "ALL",short:"ALL" },
  { value: "1", label: "Present", short: "P" },
  { value: "2", label: "Absent", short: "A" },
  { value: "3", label: "Enrolled", short:"Enrolled"},
  { value: "4", label: "Not Enrolled", short: "NE" },
  // { value: "5", label: "On Duty", short: "OD" },
  { value: "5", label: "Sunday", short: "SU" },
  { value: "6", label: "Second Saturday", short: "SS" },
  { value: "7", label: "Authorised Absent", short: "AA" },
  { value: "8", label: "Holiday", short: "H" },
  // { value: "10", label: "Half Day On Duty", short: "HDOD" },
];

export const options5 = [
  { value: "0", label: "ALL",short:"ALL" },
  { value: "1", label: "Present", short: "P" },
  { value: "2", label: "Absent", short: "A" },
  { value: "3", label: "Enrolled", short:"Enrolled"},
  { value: "4", label: "Not Enrolled", short: "NE" },
  { value: "5", label: "On Leave", short: "OL" },
  // { value: "5", label: "On Duty", short: "OD" },
  // { value: "7", label: "Half Day On Duty", short: "HDOD" },

  // { value: "5", label: "Holiday", short: "H" },
  // { value: "7", label: "Sunday", short: "SU" },
  // { value: "8", label: "Second Saturday", short: "SS" },
];

