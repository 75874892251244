import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {

    return (
        <>
            <div id="global-loader">
                <img src={require("../../assets/img/loader.svg").default} className="loader-img" alt="Loader" />
            </div>
        </>
    )
}



export default Loader;