import React from "react";
import { Button, Card, Form, FormGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReportTable from "../components/Table";
import AddUpdateModal from "../feature/AttendeeMangement/Studentmodal";
import AreUSureModal from "../components/Modals/Duplicates";

import { useDispatch } from "react-redux";

import {
  attendeeDeptCollege,
  attendeeCollegeClassList,
  attendeeAddStudent,
  attendeeUpdateStudent,
  attendeeSingleStudent,
  attendeeStudentList,
  attendeeUpdateStatus
} from "../Store/SagaActions/AttendeeMangementSagaActions"

import { toast } from "react-hot-toast";
import { monthlyDropdownDetailsAction } from "../Store/SagaActions/TableDetailSagaAction";

function AttendeeStudent() {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false); // show modal
  const [warningPopup, setWarningPopup] = useState(false); //are u sure pop up

  const [deptDropDown, setDeptDropDown] = useState([])
  const [orgDropDown, setOrgDropDown] = useState([])
  const [classDropDown, setclassDropDown] = useState([])

  const [SelectedDept, setSelectedDept] = useState("0")
  const [SelectedOrg, setSelectedOrg] = useState("0")
  const [SelectedClass, setSelectedClass] = useState("0")

  const [studentTableData, setstudentTableData] = useState([])

  const [isEdit, setisEdit] = useState(false)

  const [rowData, setRowData] = useState({})
  const [studentFullTableData, setstudentFullTableData] = useState([])

  const [infoMessage, setInfoMessage] = useState("")
  const [remarkDetails, setRemarkDetails] = useState(undefined)

  const [CallType, setCallType] = useState("")

  const [gender, setGender] = useState("")
  const [singleInfoData, setSingleInfoData] = useState({})
  const [FormDeatils, setFormDeatils] = useState({})
  const [validated, setValidated] = useState(false);
  const [addLabel, setaddLabel] = useState("")
  const[dataChange,setDataChange]=useState(false)


  const toggleSwitch = (toggle) => {
    return toggle === "1" ?
      <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.182" y="2.182" width="25.636" height="13.156" rx="6.578" stroke="#38CAB3" strokeWidth="0.364" />
        <g filter="url(#filter0_d_150_24)">
          <circle cx="19.24" cy="8.75999" r="6.24" fill="#38CAB3" />
        </g>
        <defs>
          <filter id="filter0_d_150_24" x="10" y="0.519989" width="18.48" height="18.48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_24" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_24" result="shape" />
          </filter>
        </defs>
      </svg> :
      <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.182" y="2.182" width="25.636" height="13.156" rx="6.578" stroke="#B7B7B7" strokeWidth="0.364" />
        <g filter="url(#filter0_d_150_15)">
          <circle cx="9.76002" cy="8.75999" r="6.24" fill="#B7B7B7" />
        </g>
        <defs>
          <filter id="filter0_d_150_15" x="0.52002" y="0.519989" width="18.48" height="18.48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_150_15" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_150_15" result="shape" />
          </filter>
        </defs>
      </svg>
  }

  function deptDropdown() {
    dispatch(
      monthlyDropdownDetailsAction({
        callback: (data) => {
          departmentDowndata(data)
        }
      })

    )
  };

  function GetOrgOptions(deptid) {
    let model = {
      "deptid": deptid
    }
    dispatch(
      attendeeDeptCollege({
        model,
        callback: (data) => {
          orgnisationdowndata(data)
        }
      })
    )
  }

  function GetClassOptions(collegeorgid) {
    let model = {
      "collegeorgid": collegeorgid
    }
    dispatch(
      attendeeCollegeClassList({
        model,
        callback: (data) => {
          orgnisationclassdowndata(data)
        }
      })
    )
  }



  const departmentDowndata = (data) => {
    setstudentTableData([])
    setDeptDropDown(data)
  }
  const orgnisationdowndata = (data) => {
    setstudentTableData([])
    setOrgDropDown(data?.departmentwisecollegeslistdetails)
  }

  const orgnisationclassdowndata = (data) => {
    setstudentTableData([])
    setclassDropDown(data?.collegewiseclasseslistdetails)
  }

  const OnSelectDept = (e) => {
    const { value } = e?.target
    const selectedIndex = e.target.selectedIndex
    const label = e.target[selectedIndex].label
    setaddLabel(label)
    setSelectedDept(value)
    setSelectedOrg("0")
    setSelectedClass("0")
    setOrgDropDown([])
    setclassDropDown([])
    GetOrgOptions(value)
  }
  const OnSelectOrg = (e) => {
    const { value } = e?.target
    setSelectedOrg(value)
    setSelectedClass("0")
    setclassDropDown([])
    GetClassOptions(value)
  }
  const OnSelectClass = (e) => {
    const { value } = e?.target
    setSelectedClass(value)
  }
  useEffect(() => {
    deptDropdown();
  }, []);

  const getTableData = () => {
    if (SelectedClass !== "0") {
      let model = {
        "classorgid": SelectedClass
      }
      dispatch(
        attendeeStudentList({
          model,
          callback: (data) => {
            studentList(data)
          }
        })
      )
    }
  }

  const studentList = (data) => {
    setstudentFullTableData(data?.studentlist)
    if (data?.studentlist.length === 0) toast.error("no data found")
    if (!(data?.studentlist)) toast.error("Something unexpected happened")

    const datamapped = (data?.studentlist).map((i, idx) => {
      const { departmentName, email, attendeeStatus, gender, mobile, college, attdid, attdname } = i
      const temp = {
        [`Student Id`]: attdid === "" ? "_" : attdid,
        [`Student Name`]: attdname === "" ? "_" : attdname,
        // [`${addLabel==="TMC"?"Worker":"Student"} Id`]: attdid === "" ? "_" : attdid,
        // [`${addLabel==="TMC"?"Worker":"Student"} Name`]: attdname === "" ? "_" : attdname,
        "Class": i?.class === "" ? "_" : i?.class,
        "Gender": gender === "" ? "_" : gender.toUpperCase(),
        "Mobile Number": (mobile === "_" || mobile === "") ? "_" : mobile,
        "Email ID": email === "" ? "_" : email,
        "Department": departmentName === "" ? "_" : departmentName,
        "Institute": college === "" ? "_" : college,
        "Profile": toggleSwitch(attendeeStatus),
        "Edit": <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.607 0.318308C14.4031 0.114495 14.1266 0 13.8383 0C13.55 0 13.2735 0.114495 13.0696 0.318308L11.5323 1.85665L16.1443 6.46841L17.6817 4.93116C17.8855 4.72729 18 4.45081 18 4.16253C18 3.87425 17.8855 3.59778 17.6817 3.3939L14.607 0.318308ZM14.607 8.00675L9.99385 3.3939L0.317472 13.0697C0.114227 13.2734 6.11438e-05 13.5495 0 13.8372V16.9128C0 17.2012 0.114548 17.4777 0.318443 17.6816C0.522339 17.8855 0.798881 18 1.08723 18H4.16193C4.45026 17.9999 4.72675 17.8854 4.9306 17.6815L14.607 8.00567V8.00675Z" fill="#38CAB3" />
        </svg>
        ,
      }
      return temp;
    });
    setstudentTableData(datamapped)
  }

  useEffect(() => {
    getTableData()
  }, [SelectedClass]);




  const openAddModal = (e) => {
    setRowData({})
    setValidated(false)
    setisEdit(false)
    setSingleInfoData({})
    if (isEdit === false) setGender("")
    setCallType(e)
    setShowPopup(true)
  }

  const gotoedit = () => {
    setisEdit(true)
    setSingleInfoData({})
    setCallType("edit")
  }

  const getModel = (FormDetails, _aid, _columnname) => {
    const calltype = _columnname === undefined ? CallType : _columnname
    switch (calltype) {
      case "add":
        return {
          "desid": SelectedDept,
          "collegeorgid": SelectedOrg,
          "classorgid": SelectedClass,
          "email": rowData?.email === "" ? "-" : rowData.email,
          "gender": gender,
          "roleid": "1",
          ...rowData,
        }
      case "edit":
        return {
          "aid": rowData?.aid,
          "attdname": rowData?.attdname,
          "mobile": rowData?.mobile === "" ? "-" : rowData?.mobile,
          "email": rowData?.email === "" ? "-" : rowData?.email,
          "gender": gender,
          "attdstatus": rowData?.attendeeStatus,
          "roleid": "1",
          ...rowData,
        }
      case "profile":
        return {
          "aid": rowData?.aid,
          "attdstatus": rowData?.attendeeStatus === "0" ? "1" : "0",
          "remarks": remarkDetails
        }
      case `${addLabel === "TMC" ? "worker" : "student"} id`:
      case `${addLabel === "TMC" ? "worker" : "student"} name`:
        return {
          "aid": _aid
        }
      default:
        return {}
    }

  }


  const addUpdateStudent = (e, FormDetails, _aid, _columnname) => {
    e.preventDefault()
    const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.stopPropagation();
    // }
    let model = getModel(FormDetails, _aid, _columnname)
    const calltype = _columnname === undefined ? CallType : _columnname
    switch (calltype) {
      case "add":
        if (form.checkValidity()) {
          if (gender !== "") {
            dispatch(
              attendeeAddStudent({
                model, callback: (data) => {
                  if (data?.responsecode === "200") {
                    setFormDeatils({})
                    addStudent(data)
                  }
                  else toast.error(data?.responsedesc + " Please try again")
                }
              })
            )
          }
          else {
            toast.error(" Please select gender")
          }
        }
        return
      case "edit":
        // if (FormDeatils.remarks === undefined) {
        //   toast.error("Please Fill remarks")
        //   return
        // }

        // if (Object.keys(FormDeatils).length === 1 && FormDeatils.remarks) {
        //   toast.error("No changes has been recorded")
        //   return
        // }
        // let error
        // try {
        //   Object.keys(FormDetails).forEach(i => {
        //     if (FormDetails[i] === "") {
        //       error = i
        //       return
        //     }
        //   })
        // }
        // finally {
        //   if (!error) {
        //     if (model.remarks !== undefined) {
        //       dispatch(
        //         attendeeUpdateStudent({
        //           model, callback: (data) => {
        //             if (data?.responsecode === "200") {
        //               setFormDeatils({})
        //               addStudent(data)
        //             }
        //             else toast.error(data?.responsedesc + " Please try again")
        //           }
        //         })
        //       )
        //     }
        //   }
        //   else
        //     toast.error(`Please fill ${error} field`)
        // }
        if (form.checkValidity() && dataChange) {
          if (model.remarks !== undefined) {
            dispatch(
              attendeeUpdateStudent({
                model, callback: (data) => {
                  if (data?.responsecode === "200") {
                    setFormDeatils({})
                    setRowData({})
                    addStudent(data)
                  }
                  else toast.error(data?.responsedesc + " Please try again")
                }
              })
            )
          }
        }
        else{
          if(form.checkValidity()){
            toast.error("There are no valid changes")
          }
        }
        return
      case "profile":
        dispatch(
          attendeeUpdateStatus({
            model,
            callback: (data) => {
              if (data?.responsedesc === "Success") {
                toast.success(`Profile ${model.attdstatus === "1" ? "enabled" : "disabled"} successfully`)
                setRemarkDetails("")
                getTableData()
                setTimeout(() => {
                  setWarningPopup(false)
                }, 50)
              }
              else {
                toast.error(data?.responsedesc + " Please try again")
              }
            }
          })
        )
        return
      case `${addLabel === "TMC" ? "worker" : "student"} id`:
      case `${addLabel === "TMC" ? "worker" : "student"} name`:
        dispatch(
          attendeeSingleStudent({
            model,
            callback: (data) => {
              setSingleInfoData(data?.studentdata[0])
              setShowPopup(true)
            }
          })
        )
        return
      default:
        return
    }
  }

  const addStudent = (data) => {

    setisEdit(prev => !prev)
    setShowPopup(false)
    getTableData()
    toast.success("success")
  }

  function handleClick(event, data, RowNo, columnName, columnNo) {
    const rowdata = studentFullTableData.find(i => i.attdid === data[`${addLabel === "TMC" ? "Worker" : "Student"} Id`])
    setSingleInfoData({})
    setCallType(columnName.toLowerCase())
    setGender(data?.Gender)
    setRowData(rowdata)
    switch (columnName) {
      case "Edit":
        setValidated(false)
        setWarningPopup(true)
        setInfoMessage("Edit")
        setRemarkDetails(undefined)
        setisEdit(true)
        return
      case "Profile":
        setRemarkDetails("")
        setInfoMessage(rowdata?.attendeeStatus === "1" ? "Disable Profile" : "Enable Profile")
        setWarningPopup(true)
        return
      case `${addLabel === "TMC" ? "Worker" : "Student"} Id`:
      case `${addLabel === "TMC" ? "Worker" : "Student"} Name`:
        setValidated(false)
        addUpdateStudent(event, {}, rowdata?.aid, columnName.toLowerCase())
        return
      default:
        return
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if(name!=="remarks"){
      setDataChange(true)
    }
    if (name === "gender") setGender(value)
    // setFormDeatils({ ...FormDeatils, [name]: value })
    setRowData({ ...rowData, [name]: value })
  }
  const handleAreUsure = (e) => {
    switch (CallType) {
      case "edit":
        setWarningPopup(false)
        setShowPopup(true)
        break
      case "profile":
        if (remarkDetails === "") {
          toast.error("Remarks Cannot be empty")
          break
        }
        addUpdateStudent(e)

        break
      default:
        return
    }
  }
  function textColor() {
    switch (CallType) {
      case "profile":
        return rowData?.attendeeStatus === "1" ? "0" : "1"
      case "edit":
        return "0"
      default:
        return "1"
    }
  }
  const closeModal = () => {
    setRowData({})
    setShowPopup(false);
    setisEdit(false)
  }
  return (
    <>
      <AreUSureModal
        showPopup={warningPopup}
        setShowPopup={setWarningPopup}
        titleText={infoMessage}
        handleClick={handleAreUsure}
        approvereject={textColor()}
        setRemarkDetails={setRemarkDetails}
        remarkDetails={remarkDetails}
      />
      <AddUpdateModal
        showPopup={showPopup}
        deptDropDown={deptDropDown}
        orgDropDown={orgDropDown}
        classDropDown={classDropDown}
        SelectedDept={SelectedDept}
        SelectedOrg={SelectedOrg}
        SelectedClass={SelectedClass}
        OnSelectDept={OnSelectDept}
        OnSelectOrg={OnSelectOrg}
        OnSelectClass={OnSelectClass}
        addUpdateStudent={addUpdateStudent}
        isEdit={isEdit}
        rowData={rowData}
        gender={gender}
        setGender={setGender}
        singleInfoData={singleInfoData ? singleInfoData : {}}
        handleChange={handleChange}
        FormDeatils={FormDeatils}
        gotoedit={gotoedit}
        validated={validated}
        setValidated={setValidated}
        addLabel={addLabel}
        closeModal={closeModal}
      />

      <Card className="col-12"
        style={{ marginTop: "1rem" }}
      >
        <Row className="m-4">
          <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>Department*</Form.Label>
            <Form.Select id="disabledSelect" value={SelectedDept} onChange={OnSelectDept}
            >
              <option disabled value={0}>Select Department</option>
              {
                deptDropDown?.map((item, idx) =>
                  <option key={idx} value={item?.deptid}>{item?.deptname}</option>
                )
              }
            </Form.Select>
          </FormGroup>
          <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>Institute*</Form.Label>
            <Form.Select id="disabledSelect" value={SelectedOrg} onChange={OnSelectOrg}>
              <option disabled value={0}>Select Institute</option>
              {
                orgDropDown?.sort((a, b) => a.collegename.localeCompare(b.collegename))?.map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                )
              }
            </Form.Select>
          </FormGroup>
          <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>Class*</Form.Label>
            <Form.Select id="disabledSelect" value={SelectedClass} onChange={OnSelectClass}>
              <option disabled value={0}>Select Class</option>
              {
                classDropDown?.sort((a, b) => a.classname.localeCompare(b.classname))?.map((item, idx) => <option key={idx} value={item?.classorgid}>{item?.classname}</option>
                )
              }

            </Form.Select>
          </FormGroup>

        </Row>
      </Card>
      <Card>
        {
          studentTableData.length > 0 ?
            <ReportTable

              title="Student Management"
              // title={`${addLabel==="TMC"?"Worker":"Student"} Management`}
              tableData={studentTableData}
              clickableColumns={[[0, 1, 8, 9], handleClick]}
              StyledColumns={[
                [0, 1, 2, 7, 8, 9],
                ["underline", "underline", "wrap ", "wrap ", "custom-td-txt", "badges1 w-100"],
              ]}
              // filterOptions={filterOptions}
              // selectFilteroption={selectFilteroption}
              // onSelectFilteropion={onSelectFilteropion}
              fileName={`${SelectedDept} Student List`}
              openModal={openAddModal}
              buttonText={"Add Student"}

            /> : (studentTableData.length == 0 && SelectedClass !== "0") ?
              <Card
                className="text-primary fixed"
              >
                <div className="d-flex mt-4 me-3 justify-content-end">
                  <Button className="mb-2" onClick={() => openAddModal("add")}>ADD Student</Button>
                </div>
              </Card> : <></>
        }

      </Card>
    </>
  );
}

export default AttendeeStudent;
