import React from "react";
import { Button, Card, Form, FormGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReportTable from "../components/Table";
import AddUpdateModal from "../feature/AttendeeMangement/Classmodal";
import { useDispatch } from "react-redux";

import {
  attendeeDeptCollege,
  attendeeAddClass,
  attendeeCollegeClassList,
} from "../Store/SagaActions/AttendeeMangementSagaActions"

import { monthlyDropdownDetailsAction } from "../Store/SagaActions/TableDetailSagaAction";
import AreUSureModal from "../components/Modals/Duplicates";
import { toast } from "react-hot-toast";

function AttendeeStaff() {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false); // show modal

  const [deptDropDown, setDeptDropDown] = useState([])
  const [orgDropDown, setOrgDropDown] = useState([])
  const [classDropDown, setclassDropDown] = useState([])

  const [SelectedDept, setSelectedDept] = useState("0")
  const [SelectedOrg, setSelectedOrg] = useState("0")
  const [SelectedYear, setSelectedYear] = useState("0")
  const [StaffTableData, setStaffTableData] = useState([])


  const [rowData, setRowData] = useState({})
  const [CallType, setCallType] = useState("")
  const [FormDeatils, setFormDeatils] = useState({})
  const [validated, setValidated] = useState(false); //for form validation
  const [addClassLabel, setaddClassLabel] = useState("")


  function deptDropdown() {
    dispatch(
      monthlyDropdownDetailsAction({
        callback: (data) => {
          departmentDowndata(data)
        }
      })
    )
  };

  function GetOrgOptions(deptid) {
    let model = {
      "deptid": deptid
    }
    dispatch(
      attendeeDeptCollege({
        model,
        callback: (data) => {
          orgnisationdowndata(data)
        }
      })
    )
  }

  const departmentDowndata = (data) => {
    setStaffTableData([])
    setDeptDropDown(data)
  }
  const orgnisationdowndata = (data) => {
    setStaffTableData([])
    setOrgDropDown(data?.departmentwisecollegeslistdetails)

  }
  const OnSelectDept = (e) => {
    const { value } = e?.target
    const selectedIndex = e.target.selectedIndex //blackwhite
    const label = e.target[selectedIndex].label //blackwhite
    setaddClassLabel(label)
    setSelectedDept(value)
    setSelectedOrg("0")
    setOrgDropDown([])
    GetOrgOptions(value)
  }
  const OnSelectOrg = (e) => {
    const { value } = e?.target
    setSelectedOrg(value)
    setSelectedYear("0")
    setclassDropDown([])
  }
  const OnSelectYear = (e) => {
    const { value } = e?.target
    setSelectedYear(value)
  }

  useEffect(() => {
    deptDropdown();
  }, []);

  const getTableData = () => {
    if (SelectedOrg !== "0") {
      let model = {
        "collegeorgid": SelectedOrg
      }
      dispatch(
        attendeeCollegeClassList({
          model,
          callback: (data) => {
            classList(data.collegewiseclasseslistdetails)
          }
        })
      )
    }
  }

  const classList = (data) => {
    if (data.length === 0) toast.error("no data found")
    if (!(data)) toast.error("Something unexpected happened")
    const datamapped = (data ?? []).reverse().map((i, idx) => {
      const { classorgid, classname } = i
        const temp = {
          "S No": `${idx + 1}`,
          [`Class Orgid`]: classorgid === "" ? "_" : classorgid,
          [`Class Name`]: classname === "" ? "_" : classname,
        }
        // const temp = {
        //   "S No": `${idx + 1}`,
        //   [`${addClassLabel==="TMC"?"Zone":"Class "} Id`]: classorgid === "" ? "_" : classorgid,
        //   [`${addClassLabel==="TMC"?"Zone":"Class "} Name`]: classname === "" ? "_" : classname,
        // }
        return temp;      
    });
    setStaffTableData(datamapped)
  }


  useEffect(() => {
    getTableData()
  }, [SelectedOrg]);

  const openModal = (e) => {
    setSelectedYear("0")
    setValidated(false)
    setCallType(e)
    setShowPopup(true)
  }

  const addUpdateClass = (e, FormDetails) => {
    let temp, model
    if (e) {
      e.preventDefault()
    }
    const form = e.currentTarget;
    switch (CallType) {
      case "add":
        if(form.checkValidity()){
          temp = {
            "deptid": SelectedDept,
            "collegeorgid": SelectedOrg,
            "year": SelectedYear,
          }
          model = { ...temp, ...FormDetails }
          dispatch(
            attendeeAddClass({
              model, callback: (data) => {
                if (data?.responsecode === "200") {
                  toast.success("success")
                  addClass(data)
                }
                else toast.error(data?.responsedesc + " Please try again")
              }
            })
          )
        }
        return
      default:
        return
    }
  }

  const addClass = (data) => {
    setShowPopup(false)
    getTableData()
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if(name==="year") OnSelectYear(e)
    setFormDeatils({ ...FormDeatils, [name]: value })
  }

  return (
    <>
      <AddUpdateModal
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        deptDropDown={deptDropDown}
        orgDropDown={orgDropDown}
        SelectedDept={SelectedDept}
        SelectedOrg={SelectedOrg}
        SelectedYear={SelectedYear}
        OnSelectDept={OnSelectDept}
        OnSelectOrg={OnSelectOrg}
        OnSelectYear={OnSelectYear}
        addUpdateClass={addUpdateClass}
        rowData={rowData}
        FormDeatils={FormDeatils}
        handleChange={handleChange}        
        validated={validated}
        setValidated={setValidated}
        addClassLabel={addClassLabel}
      />
      <Card className="col-12"
        style={{ marginTop: "1rem" }}
      >
        <Row className="m-4">
          <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>Department*</Form.Label>
            <Form.Select id="disabledSelect" value={SelectedDept} name={"attdid"} onChange={OnSelectDept}>
              <option disabled value={0}>Select Department</option>
              {
                deptDropDown?.map((item, idx) =>
                  <option key={idx} value={item?.deptid} label={item?.deptname}>{item?.deptname}</option>
                )
              }
            </Form.Select>
          </FormGroup>
          {/* <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>
            {`${addClassLabel==="TMC"?"Zone*":"College*"}`}
            </Form.Label>
            <Form.Select id="disabledSelect" value={SelectedOrg} name={"collegeorgid"} onChange={OnSelectOrg}>
              <option disabled value={0}>Select {`${addClassLabel==="TMC"?"Zone*":"College*"}`}</option>
              {
                orgDropDown?.sort((a, b)=>  a.collegename.localeCompare(b.collegename)).map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                )
              }
            </Form.Select>
          </FormGroup> */}
            <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>
            Institute
            </Form.Label>
            <Form.Select id="disabledSelect" value={SelectedOrg} name={"collegeorgid"} onChange={OnSelectOrg}>
              <option disabled value={0}>Select Institute</option>
              {
                orgDropDown?.sort((a, b)=>  a.collegename.localeCompare(b.collegename)).map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                )
              }
            </Form.Select>
          </FormGroup>
        </Row>
              </Card>
        <Card>
        {
          StaffTableData.length > 0 ?
          <ReportTable
            // title={`${addClassLabel=="TMC"?"Zone":"Class"}`}
            title="Class"
            tableData={StaffTableData}
            fileName={`${SelectedDept} Class List`}
            openModal={openModal}
            buttonText={"Add Class"}

          />:  (StaffTableData.length == 0 && SelectedOrg !== "0") ?
              <Card>
              <div className="d-flex mt-4 me-3 justify-content-end">
            <Button className="mb-2 " onClick={()=>openModal("add")}>ADD Class</Button>
            </div>
              </Card>:<></>
        }
      </Card>
    </>
  );
}

export default AttendeeStaff;
