import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import Axios from "../../services/axios";
import {
  ACTION_POST_LISTOFDISTRICT_REQ,
  ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ,
  ACTOIN_POST_STAFF_DETAILS_REQ,
  ACTOIN_POST_STUDENT_DETAILS_REQ,
  ACTOIN_POST_SCWELFARE_DETAILS_REQ,
  ACTOIN_POST_MONTHLY_DROPDOWN_REQ,
  ACTOIN_POST_MONTHLY_DETAILS_REQ,
  ACTOIN_POST_MONTHLY_PRINCIPAL_DETAILS_REQ,
  ACTOIN_POST_MONTHLY_REPORTS_DOWNLOADS_REQ,
  ACTION_POST_ATTENDANCE_REPORTS_DETAILS_REQ,
  ACTION_POST_ATTENDANCE_REPORTS_SEARCH_REQ,
  ACTION_POST_DWM_REPORTS_REQ,
  ACTION_POST_ALLPUNCHES_REPORTS_REQ,
  ACTION_POST_FILO_PUNCHES_REPORTS_REQ,
  ACTION_POST_LATE_ARRIVAL_REPORTS_REQ,
  ACTION_POST_LEAVE_DETAILS_REPORTS_REQ,
  ACTION_POST_CONTIUOUS_ABSENT_REPORTS_REQ,
  ACTION_POST_ATTENDANCE_VIEW_REPORTS_REQ,
  ACTION_POST_PROGRESS_REPORTS_REQ,
  ACTION_POST_LEAVE_SUMMARY_REPORTS_REQ,
  ACTION_POST_ATTENDANCE_VERIFICATION_REPORTS_REQ,
  ACTION_POST_HIERARCHYWISE_STAFF_LIST_REQ
} from "../SagaActions/SagaActionTypes";
import { getAPI, postAPI } from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";


function* districtMandalDetailsSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/dashboard/getdistrictmandalwisedetails",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      // action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    }
  } catch (err) {
    // action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
}

function* getStudentStaffAttendanceSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/frsdbapi/api/dashboard/attendeeattendance",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
function* staffdetailsActionSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/dashboard/staffdetails",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }
    yield put(actionReqResStatusLoaderSagaAction(false));
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
  finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
function* studentdetailsActionSaga(action) {

  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/dashboard/studentdetails",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
  finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* scWelfaredetailsActionSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/dashboard/scwelfarereport",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postmonthlyDropdownDetailsActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    getAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/college-departments",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postmonthlyDetailsActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/departmentwise-monthlyreport",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postmonthlydownloadreportsActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/downloads",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postmonthlyPrincipalDetailsActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/monthlyreport",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}



function* attendanceReportActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/getchildorganisations",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* attendanceReportsearchReqSaga(action) {
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/searchorganisation",
    action?.payload?.model
  );
  try {
    action?.payload?.callback(resp);
  } catch (err) {
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


function* dwmActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/dwmreport",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* allPunchesActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/allpunches",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* filoPunchesActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/firstinlastout",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* lateArrivalActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/latearrival",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* leaveDetailsActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/leavedetails",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* continuousAbsentActionReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));

  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BACKJOB_URL +
    "/reports/continuousabsent",
    action?.payload?.model,
    process.env.REACT_APP_BACKJOB_KEY
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
function* progressReportReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/dashboard/performancereport",
    action?.payload?.model
  );
  try {
    action?.payload?.callback(resp);
  } catch (err) {
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* attendanceViewReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/dashboard/attendance-report",
      action?.payload?.model
    );
    action?.payload?.callback(resp);
  } catch (err) {
    action?.payload?.callback([]);
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* leaveSummaryReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/dashboard/leave-summary",
      action?.payload?.model
    );
    action?.payload?.callback(resp);
  } catch (err) {
    action?.payload?.callback([]);
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* attendanceVerificationReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/dashboard/attendance-verification-report",
      action?.payload?.model
    );
    action?.payload?.callback(resp);
  } catch (err) {
    action?.payload?.callback([]);
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* hierarchywiseStaffListReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/dashboard/staff-list",
      action?.payload?.model
    );
    action?.payload?.callback(resp);
  } catch (err) {
    action?.payload?.callback([]);
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

export default function* TableDetailsWatcherSaga() {
  yield takeLatest(ACTION_POST_LISTOFDISTRICT_REQ, districtMandalDetailsSaga);
  yield takeLatest(ACTOIN_POST_STAFF_DETAILS_REQ, staffdetailsActionSaga);
  yield takeLatest(ACTOIN_POST_STUDENT_DETAILS_REQ, studentdetailsActionSaga);
  yield takeEvery(ACTION_POST_STUDENTSTAFF_ATTEDANCE_REQ, getStudentStaffAttendanceSaga);
  yield takeEvery(ACTOIN_POST_SCWELFARE_DETAILS_REQ, scWelfaredetailsActionSaga);
  yield takeEvery(ACTOIN_POST_MONTHLY_DROPDOWN_REQ, postmonthlyDropdownDetailsActionReqSaga);
  yield takeEvery(ACTOIN_POST_MONTHLY_DETAILS_REQ, postmonthlyDetailsActionReqSaga);
  yield takeEvery(ACTOIN_POST_MONTHLY_PRINCIPAL_DETAILS_REQ, postmonthlyPrincipalDetailsActionReqSaga);
  yield takeEvery(ACTOIN_POST_MONTHLY_REPORTS_DOWNLOADS_REQ, postmonthlydownloadreportsActionReqSaga);
  yield takeEvery(ACTION_POST_ATTENDANCE_REPORTS_DETAILS_REQ, attendanceReportActionReqSaga);
  yield takeEvery(ACTION_POST_ATTENDANCE_REPORTS_SEARCH_REQ, attendanceReportsearchReqSaga);
  yield takeEvery(ACTION_POST_DWM_REPORTS_REQ, dwmActionReqSaga);
  yield takeEvery(ACTION_POST_ALLPUNCHES_REPORTS_REQ, allPunchesActionReqSaga);
  yield takeEvery(ACTION_POST_FILO_PUNCHES_REPORTS_REQ, filoPunchesActionReqSaga);
  yield takeEvery(ACTION_POST_LATE_ARRIVAL_REPORTS_REQ, lateArrivalActionReqSaga);
  yield takeEvery(ACTION_POST_LEAVE_DETAILS_REPORTS_REQ, leaveDetailsActionReqSaga);
  yield takeEvery(ACTION_POST_CONTIUOUS_ABSENT_REPORTS_REQ, continuousAbsentActionReqSaga);
  yield takeEvery(ACTION_POST_PROGRESS_REPORTS_REQ, progressReportReqSaga);
  yield takeEvery(ACTION_POST_ATTENDANCE_VIEW_REPORTS_REQ, attendanceViewReqSaga);
  yield takeEvery(ACTION_POST_LEAVE_SUMMARY_REPORTS_REQ, leaveSummaryReqSaga);
  yield takeEvery(ACTION_POST_ATTENDANCE_VERIFICATION_REPORTS_REQ, attendanceVerificationReqSaga);
  yield takeEvery(ACTION_POST_HIERARCHYWISE_STAFF_LIST_REQ, hierarchywiseStaffListReqSaga);

}

