import React, { useEffect, useState } from "react";
import { Button, Modal, Dropdown, Card } from "react-bootstrap";
import Profile from "./TicketDescription";
import TicketAssign from "./Assign";
import { Assign } from "../../utils";
import Select from "react-select";
import { toast } from "react-hot-toast";

const Detail = (props) => {
  const {
    showPopup,
    setShowPopup,
    // TicketData: { status, ticket_ID, assignee },
    ticketHistory,
    Assignlist,
    AssignHandler,
    ChatNstatus,
    fetchTicketHistory,
    selectedOption,
    setSelectedOption,
    onSelectAssigndropdown,
    showDropdown,
    setshowDropdown,
    reAssigne,
    reAssigneShow,
    setReAssigneShow,
    descriptionOption,
    setDescriptionOption,
    selectdescriptiondropdown,
    clickedColumn,
    descriptionSelects,
    setDescriptionSelects
  } = props;
  const Status = Assign(props?.TicketData?.status);
  const  userRole  = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;
  const full = !(Status === "Assign" || reAssigneShow === true)
  return (

    <Modal
      show={showPopup}
      size={full ? "lg" : ""}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
    >
      <Modal.Header>
        <Modal.Title className="text-center">
          {Status === "Assign" ? "Assign" : "Ticket Details"}
        </Modal.Title>
        <Button
          variant=""
          className="btn btn-close"
          onClick={() => {
            setReAssigneShow(false);
              setDescriptionSelects&& setDescriptionSelects(false)
            
            setShowPopup(false);
          }}
        >
          x
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-1 ">
        {(((Status === "Assign" && clickedColumn===10) || reAssigneShow === true) && userRole === "ADMIN") ? (
          <TicketAssign
            selectedOption={selectedOption}
            options={Assignlist}
            onSelectAssigndropdown={onSelectAssigndropdown}
            ticket_ID={props?.TicketData?.ticket_ID}
            AssignHandler={AssignHandler}
            reAssigneShow={reAssigneShow}
            setReAssigneShow={setReAssigneShow}
            status={props?.TicketData?.status}
            
          // assignee={assignee}
          />
        ) : (
          <>
            <Card>
              <div className="d-flex justify-content-between align-items-center pd-15">
                <div className="font-weight-semibold me-2">
                  Ticket Id :<span>{props?.TicketData?.ticket_ID}</span>
                </div>
                <div class="success-widget d-flex align-items-center ">
                  <div class={`mx-1 ${Assign(Status)} d-flex`}>
                    Assignee : {props?.TicketData?.assignee}
                    {(userRole === "ADMIN" && Status != "Closed") && (
                      <div class="d-flex ms-2 me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="header-icon-svgs"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          onClick={reAssigne}
                        >
                          <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                          <path d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"></path>
                        </svg>
                      </div>
                    )}
                    {" "}
                    Status : {Status}
                  </div>
                </div>
              </div>
            </Card>
            <Profile
              TicketData={props.TicketData}
              ticketHistory={ticketHistory}
              fetchTicketHistory={fetchTicketHistory}
              ChatNstatus={ChatNstatus}
              descriptionOption={descriptionOption}
              setDescriptionOption={setDescriptionOption}
              selectdescriptiondropdown={selectdescriptiondropdown}
              Status={Status}
              descriptionSelects={descriptionSelects}
              selectedOption={selectedOption}
              onSelectAssigndropdown={onSelectAssigndropdown}
              options={Assignlist}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default Detail;
