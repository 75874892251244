import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./butoon.css";

import { Card, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  principalDeuplicate,
  principalDeuplicateStatus,
} from "../../Store/SagaActions/PrincipalSagaActions";
import Modal from "../../components/Modals/Duplicates";
import ImageModal from "../../components/Modals/ImageModal";
import { ApproveReject, Cards, thumbnailToimg } from "./Functions";

var tabledata = []
function Duplicates() {
  const [showPopup, setShowPopup] = useState(false); // show modal
  const [imgShowPopup, setImgShowPopup] = useState(false); // show modal
  const [tablecol, setTableCol] = useState([]);      // cards data
  const [items, setitems] = useState(Array.from({ length: 6 })) //infinite loading
  const [pagenumber, setPagenumber] = useState(1)    
  const [has, sethas] = useState(true)
  const [approvereject, setapprovereject] = useState("")   
  const [appdata, setappdata] = useState({})

  const [imgs, setImgs] = useState({
    img1:"",
    img2:""
  })

  const dispatch = useDispatch();
  
  //getting cards data
  let orgid = JSON.parse(sessionStorage.getItem("authkey")).orgid;
  const getDeduplicateDataList = () => {
    let model = {
      groupid: orgid,
    };
    dispatch(
      principalDeuplicate({ model, callback: (data) => duplicaeList(data) })
    );
  };

    //accepting/reejecting request
  const userid = `${JSON.parse(sessionStorage.getItem("authkey")).uid}`;
  const approvedEvent = (e,Status, data) => {
    const { adhid } = data;
    const model = { adhid: adhid, Status: Status, userid: userid };
    dispatch(
      principalDeuplicateStatus({
        model,
        callback: () => {
          setShowPopup(false)
          getDeduplicateDataList()
        },
      })
    );
  };


  //mapping data
  const duplicaeList = (data) => {
    const datamapped = data?.map((i, idx) => {
      const { attendeeid, attendeename,
        college_or_classname, designation, thumbnail,
        dattendeeid, dattendeename, dcollege_or_classname, ddesignation, dthumbnail,
      } = i;

     
      return {
        attendeeid, dattendeeid, attendeename, dattendeename,
        college_or_classname, dcollege_or_classname, designation,
        ddesignation, "thumbnail": thumbnailToimg(thumbnail),
        "dthumbnail": thumbnailToimg(dthumbnail),
        img1: thumbnail,
        img2: dthumbnail,
        "apreject": <ApproveReject data={i} popUPShow={popUPShow} />
      }
    });
    tabledata = datamapped
    setitems(tabledata.filter((i, idx) => idx < pagenumber * 8))
    setTableCol(tabledata)
  };

  //data to modal
  const popUPShow = (status, data) => {
    setShowPopup(true)
    setapprovereject(status)
    setappdata(data)
  }

  const fetchMoreData = () => {
    setPagenumber(prev => prev + 1)
    setTimeout(() => {
      setitems(tabledata.filter((i, idx) => idx < pagenumber * 6));
    }, 1000);
  };


  useEffect(() => {
    getDeduplicateDataList();
  }, []);

  useEffect(() => {
    if (items.length === tabledata.length) sethas(false);
  }, [items]);


  const handleImgClick = (img1, img2) => { 
    setImgs({
      img1,
      img2
    })
    setImgShowPopup(true)

  };


  return (
    // <Card className="text-primary"
    //           style={{ marginTop: "2rem", marginBottom: "2rem", padding: "1rem" }}

    // > Coming Soon</Card>
    <>

      <ImageModal
        showPopup={imgShowPopup}
        img1={imgs.img1}
        img2={imgs.img2}
        handleClose={() => setImgShowPopup(false)}
      />
      <Modal
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        handleClick={approvedEvent}
        approvereject={approvereject}
        data={appdata}
        titleText={approvereject === "1" ? "Approve":"Reject"}
      />
      <div className="justify-content-between mt-3">
            <div className="left-content d-none d-md-block d-lg-block">
              <span className="mg-b-0 mg-b-lg-1 font-weight-bold">
              {tabledata.length} Duplicates Data Found
              </span>
            </div>
            <div className="justify-content-center mt-2"></div>
            {/* <div>
            <span> Showing {tablecol.length} at a time</span>
            </div> */}
          </div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={has}
        loader={<h4>Loading...</h4>}
      >
        <Card
          style={{ marginTop: "2rem", marginBottom: "2rem", padding: "1rem" }}
        >
          <Row className="row"
            style={{ marginTop: ".5rem" }}
          >
            {items.map((i, index) => (
              <>
                <Cards item={i} handleImgClick={handleImgClick} setImgShowPopup={setImgShowPopup} />
              </>
            ))}
          </Row>
        </Card>
      </InfiniteScroll>
    </>
  );
}

export default Duplicates;
