import toast from "react-hot-toast";
import { call, put, takeLatest } from "redux-saga/effects";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";
import {
    ACTION_POST_ADMIN_NOTIFICATION_LIST_REQ,
    ACTION_POST_ADMIN_NOTIFICATION_READ_LIST_REQ,
    ACTION_POST_ADMIN_PUSH_NOTIFICATION_REQ,
    ACTION_POST_NOTIFICATION_ADD_REQ,
    ACTION_POST_NOTIFICATION_DELETE_REQ,
    ACTION_POST_NOTIFICATION_LIST_REQ,
    ACTION_POST_NOTIFICATION_LOGS_REQ,
    ACTION_POST_NOTIFICATION_UPDATE_REQ,
    ACTION_POST_PUSH_NOTIFICATION_REQ,
    ACTION_POST_SEND_SMS_REQ,
    ACTION_POST_SEND_WHATSAPP_NOTIFICATION_REQ,
    ACTION_POST_SMS_TEMPLATE_NOTIFICATION_REQ,
    ACTION_POST_WHATSAPP_NOTIFICATION_REQ
} from "../SagaActions/SagaActionTypes";
import { postAPI } from "./ApiMethods";


function* notificationListSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/list",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

function* notificationAddSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/add",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
function* notificationUpdateSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/update",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

function* notificationDeleteSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/delete",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

function* whatsAppNotificationSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/whatsapp-templates",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
function* smsTemplateNotificaitonSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/sms-templates",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}


function* sendSmsSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/send-sms",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}

function* notificationLogsSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/notification-logs",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
function* pushNotificationSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/send-push-notification",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback([]);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
function* sendWhatsAppNotificationSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/notifications/send-whatsapp-notification",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback(err);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
function* adminPushNotificationLSaga(action){
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/dashboard/version-upgrade-notification",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback(err);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
function* adminNotificationListSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/dashboard/notifications-list",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback(err);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
function* adminNotificationReadListSaga(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    try {
        const resp = yield call(
            postAPI,
            process.env.REACT_APP_BASE_URL +
            "/api/dashboard/notifications-read-list",
            action?.payload?.model
        );
        action?.payload?.callback(resp);
    } catch (err) {
        action?.payload?.callback(err);
        if (err.response) {
            toast.error(
                err?.response?.data?.errors?.length &&
                err?.response?.data?.errors[0]?.message
            );
        }
    } finally {
        yield put(actionReqResStatusLoaderSagaAction(false));
    }
}
export default function* NotificationWatcherSaga() {
    yield takeLatest(ACTION_POST_NOTIFICATION_LIST_REQ, notificationListSaga);
    yield takeLatest(ACTION_POST_NOTIFICATION_ADD_REQ, notificationAddSaga);
    yield takeLatest(ACTION_POST_NOTIFICATION_UPDATE_REQ, notificationUpdateSaga);
    yield takeLatest(ACTION_POST_NOTIFICATION_DELETE_REQ, notificationDeleteSaga);
    yield takeLatest(ACTION_POST_WHATSAPP_NOTIFICATION_REQ, whatsAppNotificationSaga);
    yield takeLatest(ACTION_POST_SMS_TEMPLATE_NOTIFICATION_REQ, smsTemplateNotificaitonSaga);
    yield takeLatest(ACTION_POST_SEND_SMS_REQ, sendSmsSaga);
    yield takeLatest(ACTION_POST_NOTIFICATION_LOGS_REQ, notificationLogsSaga);
    yield takeLatest(ACTION_POST_PUSH_NOTIFICATION_REQ, pushNotificationSaga);
    yield takeLatest(ACTION_POST_SEND_WHATSAPP_NOTIFICATION_REQ, sendWhatsAppNotificationSaga);
    yield takeLatest(ACTION_POST_ADMIN_PUSH_NOTIFICATION_REQ, adminPushNotificationLSaga);
    yield takeLatest(ACTION_POST_ADMIN_NOTIFICATION_LIST_REQ, adminNotificationListSaga);
    yield takeLatest(ACTION_POST_ADMIN_NOTIFICATION_READ_LIST_REQ, adminNotificationReadListSaga);

}