import React, { useState } from "react";
import { Card, Col, Dropdown, Nav, Row, Tab, FormGroup, Form, Button,} from "react-bootstrap";
import { Assign, colourStyles, despOptions } from "../../utils";
import Select from "react-select";
import TicketDetails from "./TicketDetails";

const Profile = (props) => {
  const {
    TicketData,
    ticketHistory,
    fetchTicketHistory,
    ChatNstatus,
    descriptionOption,
    setDescriptionOption,
    selectdescriptiondropdown,
    Status,
    descriptionSelects,
    onSelectAssigndropdown,
    options,
  } = props;
  return (
    <div style={{ maxHeight: "400px", overflowY: "scroll", overflowX: "hidden" }}>
      <Row>
        <Col lg={12} md={12}>
          <span className=" py-0 ">
            <div className="profile-tab tab-menu-heading border-bottom-0 ">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Profile">
                <Nav
                  variant="pills"
                  className="nav profile-tabs main-nav-line tabs-menu profile-nav-line bg-white border-0 br-5 mb-0	"
                >
                  <Nav.Item className="me-1">
                    <Nav.Link className=" mb-2 mt-2" eventKey="Profile">
                      Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1">
                    <Nav.Link className="mb-2 mt-2" eventKey="Description">
                      Description
                    </Nav.Link>
                  </Nav.Item>
                  {//left-tabs-example-tab-History
                  }
                  <Nav.Item className="me-1" onClick={fetchTicketHistory}>
                    <Nav.Link className="mb-2 mt-2" eventKey="History">
                      History
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <TicketDetails
                  TicketData={props.TicketData}
                  descriptionOption={descriptionOption}
                  ChatNstatus={ChatNstatus}
                  ticketHistory={ticketHistory}
                  setDescriptionOption={setDescriptionOption}
                  selectdescriptiondropdown={selectdescriptiondropdown}
                  Status={Status}
                  descriptionSelects={descriptionSelects}
                  options={options}
                  onSelectAssigndropdown={onSelectAssigndropdown}
                  fetchTicketHistory={fetchTicketHistory}
                />
              </Tab.Container>
            </div>
          </span>
        </Col>
      </Row>
    </div>
  );
};

Profile.propTypes = {};
Profile.defaultProps = {};
export default Profile;
