import React, { useEffect } from 'react'
import ReportTable from '../components/Table'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { notificationLogsAction } from '../Store/SagaActions/NotificationSagaAction';
import LoaderComp from '../components/LoaderComp/LoaderComp';

const NotificationLogs = () => {

  const dispatch = useDispatch()
  const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;
  
  const [notificationTableData, setNotificationTableData] = useState([])


  const notificationList = (data) => {
    if (data.length === 0) toast.error("No data found")
    const datamapped = data?.notificationlogsdetails.map((i, idx) => {
      const {title, message,senton,successcount,failcount, status } = i
      const temp = {
        "Title": title,
        "Message": message,
        "Sent On":senton,
        "Success Count":successcount,
        "Fail Count":failcount,
        "Status": status==="1"?"Active":"Inactive",
      }
      return temp;
    })
    setNotificationTableData(datamapped)
  }

  const getNotificationList = () => {
    if (userRole === "PRINCIPAL") {
      dispatch(
        notificationLogsAction({
          callback: (data) => {
            notificationList(data)
          }
        })
      )
    }
  }


  useEffect(() => {
    getNotificationList()
  }, [])


  return (
    <div className='mt-3'>
      <LoaderComp/>
      {notificationTableData.length > 0 ?
        <div className="main justify-content-between">
          <ReportTable
            title={'Notifications Logs'}
            tableData={notificationTableData}
            fileName={"Notification logs"}
            StyledColumns={[
              [1],
              ["wrap"],
            ]}
          />
        </div>
        :
        <></>
      }

    </div>
  )
}
export default NotificationLogs