import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Form, FormGroup } from "react-bootstrap";
import { useIsMobile } from "../../utils/hooks";


const Classmodal = (props) => {
    const {
        showPopup,
        setShowPopup,
        deptDropDown,
        orgDropDown,
        SelectedDept,
        SelectedOrg,
        SelectedYear,
        OnSelectDept,
        OnSelectOrg,
        addUpdateClass,
        validated,
        setValidated,
        FormDeatils,
        handleChange,
        addClassLabel,
    } = props;
    const [nameClass, setNameClass] = useState("84px");
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            setNameClass("201px")
        }
        else {
            setNameClass("84px")
        }
    }, [isMobile])
    

    return (

        <Modal
            show={showPopup}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            style = {{zIndex:"9991"}}            

        >
            <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
                <Modal.Title className="text-center">
                    Add Class
                    {/* Add {addClassLabel==="TMC"?"Zone":"Class"} */}
                </Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close"
                    onClick={() => {
                        setShowPopup(false);
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body className="pt-1 ">
                <Card className="mt-3">
                    <Form noValidate
                        className="needs-validation"
                        onSubmit={(e) => {
                            addUpdateClass(e, FormDeatils)
                            setValidated(true);
                        }}
                        validated={validated}
                    >
                        <div className="row" style={{ height: nameClass }}>
                            <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                <Form.Label>Department*</Form.Label>
                                <Form.Select id="disabledSelect" value={SelectedDept} name={"attdid"} onChange={OnSelectDept} disabled >
                                    <option disabled value={0}>Select Department</option>
                                    {
                                        deptDropDown?.map((item, idx) =>
                                            <option key={idx} value={item?.deptid} label={item?.deptname}>{item?.deptname} </option>
                                        )
                                    }
                                </Form.Select>
                            </FormGroup>
                            <FormGroup className="text-start form-group col-12 col-sm-6">
                                <Form.Label>Institute*</Form.Label>
                                <Form.Select id="disabledSelect" value={SelectedOrg} name={"collegeorgid"} onChange={OnSelectOrg} size="sm">
                                    <option disabled value={0}>Select Institute</option>
                                    {
                                        orgDropDown?.map((item, idx) => <option key={idx} value={item?.collegeorgid}>{item?.collegename}</option>
                                        )
                                    }
                                </Form.Select>
                            </FormGroup>
                        </div>
                        <div className="row" style={{ height: nameClass }}>
                            <FormGroup className="text-start form-group col-12 col-sm-6">
                                <Form.Label>Class*</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    placeholder="Enter Class"
                                    id="email"
                                    name={"classname"}
                                    required
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide class name.
                                </Form.Control.Feedback>
                            </FormGroup>
                            <FormGroup className="text-sm form-group col-12 col-sm-6 ">
                                <Form.Label>Class Code*</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    placeholder="Enter Class Code"
                                    id="email"
                                    // minLength="10"
                                    name={"classCode"}
                                    required
                                    onChange={handleChange}
                                    maxLength= {18}

                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide class code.
                                </Form.Control.Feedback>
                            </FormGroup>
                        </div>

                        <div className="row" style={{ height: nameClass }}>

                            <FormGroup className="text-start form-group col-12 col-sm-6">
                                <Form.Label>Year*</Form.Label>
                                <Form.Select id="disabledSelect"
                                    value={SelectedYear}
                                    name={"year"}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select year</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select year.
                                </Form.Control.Feedback>
                            </FormGroup>
                            <Button
                                disabled={false}
                                type='submit'
                                className="btn "
                                style={{ position: "absolute", right: "16px", bottom: "6px" }}
                            >
                                ADD

                            </Button>

                        </div>

                    </Form>

                </Card>

            </Modal.Body>
        </Modal>
    );
};
export default Classmodal;
