import moment from 'moment';
import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportHeader from '../components/Table/ReportHeader';
import { useDispatch } from "react-redux";
import { staffdetailsAction, studentDetailsAction } from '../Store/SagaActions/TableDetailSagaAction';
import { badgevalue } from "../components/Table/data";
import ReportTable from '../components/Table';
import { Card } from 'react-bootstrap';
import { options5,studentOptions } from './Reports/ConstantReports';
import { pathName } from '../utils/Path';

const PrincipalReports = (props) => {
  const location = useLocation();
  const filterValue=location?.state?.data
  const rdate=location?.state?.date
  const values=filterValue?.split(" ")
  const [type, setType] = useState(()=>location?.state?.text)
  const [filter, setFilter] = useState(()=>{
    return values?.slice(0, -1).join(" ")
  })

  const [principalTableData, setPrincipalTableData] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectFilteroption, setSelectFilteroption] = useState(filterOptions[0]);
  const [Holiday,setHoliday] = useState("")

  const [formatDate, setFormatDate] = useState(rdate);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const moveToDashboard = () => {
    navigate(pathName.dashboard);
  };

  useEffect(() => {
    principalReportData();
  }, [])
  const principalReportData = (date) => {
    const Deptid = sessionStorage.getItem("principalDeptid")
    let calldate=date?date:formatDate 
    if (filter != undefined) {
      if (filter == "Total") {
        setSelectFilteroption(studentOptions[0])
      }
      if (filter == "Present") {
        setSelectFilteroption(studentOptions[1])
      }
      else if (filter == "Absent") {
        setSelectFilteroption(studentOptions[2])
      }
      else if (filter == "Enrolled") {
        setSelectFilteroption(studentOptions[3])
      }
      else if (filter == "On Leave") {
        setSelectFilteroption(options5[5])
      }
      else if (filter == "On Duty") {
        setSelectFilteroption(options5[6])
      }
      else if (filter == "Authorised") {
        setSelectFilteroption(studentOptions[7])
      }
    }
    setPrincipalTableData([]);
    let homepageOrgid = JSON.parse(sessionStorage.getItem("authkey")).orgid;
    let model = {
      deptid: Deptid,
      rdate: calldate,
      orgid: homepageOrgid,
    };

    if (type == "staff") {
      dispatch(
        staffdetailsAction({
          model,
          callback: (data) => staffdetailsActionResp(data, type),
        })
      );
    }
    else if (type == "students") {
      dispatch(
        studentDetailsAction({
          model,
          callback: (data) => staffdetailsActionResp(data, type),
        })
      );
    }
  }
  const staffdetailsActionResp = (data, type) => {
    if (type == "staff") {
      const datamapped = data?.staffDetails?.map((i, idx) => {
        const { courses_mapped, emp_id, emp_name, intime, outtime, status } = i;
        return {
          "Staff ID": emp_id,
          "Staff Name": emp_name,
          Course: courses_mapped == "not mapped" ? "_" : courses_mapped,
          "In Time": intime ? intime : "_",
          "Out Time": outtime ? outtime : "_",
          status: status,
        };
      });
      if(data?.holiday!=="" && data?.presentstaff==="0"){
        setHoliday(data?.holiday)
      }
      else{
        setHoliday("")
      }
      setFilterOptions(options5);
      setPrincipalTableData(datamapped);
    }
    if (type == 'students') {
      const datamapped = data?.studentDetails?.map((i, idx) => {
        const { class_name, student_id, student_name, intime, outtime, status } = i;
        return {
          "Student ID": student_id,
          "Student Name": student_name,
          Course: class_name == "not mapped" ? "_" : class_name,
          "In Time": intime ? intime : "_",
          "Out Time": outtime ? outtime : "_",
          status: status,
        };
      });

      const datamappedFilter = data?.studentDetails?.map(i => i.status);
      const option = [... new Set(datamappedFilter)]
      const options = option.map((i, idx) => {
        return ({
          value: idx + 1,
          label: badgevalue(i),
          short: i
        })
      });
      if(data?.holiday!=="" && data?.presentstudent==="0"){
        setHoliday(data?.holiday)
      }
      else{
        setHoliday("")
      }
      setFilterOptions(studentOptions);
      setPrincipalTableData(datamapped);
    }

  };
  const onSelectFilteropion = (e,labelText,shortLabel) => {
    if(typeof(e)==="string"){
      setSelectFilteroption({value:e,label:labelText,short:shortLabel})
    }
    else{
      const { value} = e?.target
      const selectedIndex = e.target.selectedIndex
      const label = e.target[selectedIndex].label
      if(label==="ALL") setSelectFilteroption({value,label,short:label});
      else if(label==="Enrolled") setSelectFilteroption({value,label,short:label});
      else if(label==="Sunday") setSelectFilteroption({value,label,short:"SU"});
      else{
        const short=label.split(' ').map(i => i.charAt(0)).join('')
        setSelectFilteroption({value,label,short});
      }
    }
  }

  const onSelectDate = (e) => {
    setFormatDate(moment(e)?.format("YYYY-MM-DD"));
    const date=moment(e)?.format("YYYY-MM-DD")
    localStorage.setItem("rdate",moment(e)?.format("YYYY-MM-DD"))
    principalReportData(date);
  };

  return (
    <Card>
      {principalTableData.length!="0"?
      <Card>
      <ReportHeader
        title={"Dashboard"}
        subtitle={localStorage.getItem("deptnameforcollegepage") + " Details"}
        pageName={"Reports"}
        moveToDashboard={moveToDashboard}
      />
      {principalTableData && (principalTableData.length > 0) &&
        <ReportTable
          title={`List of ${type}`}
          tableData={principalTableData}
          Holiday={Holiday}
          filterOptions={filterOptions}
          selectFilteroption={selectFilteroption}
          onSelectFilteropion={onSelectFilteropion}
          selectedDate={new Date(formatDate)}
          onSelectDate={onSelectDate}
          StyledColumns={[[2, 5], ["wrap","badges"]]}
          fileName={`${type}`}
          type={type}
          badgeBG={true}
        />}
      </Card>:
      <div class="side-app">
        <div class="main-container container-fluid">
          <div class="text-primary card" style={{paddingTop: "1rem"}}>
            No data found
            </div>
            </div>
            </div>
      }
    </Card>
  )
}

export default PrincipalReports
