import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { auditLogListAction, auditLogUserAction } from '../../Store/SagaActions/AuditLogSagaAction'

const AuditLogPresenter = () => {
  const dispatch = useDispatch()
  const [auditLogsUser, setAuditLogsUser] = useState([])
  const [auditTableData, setauditTableData] = useState([])
  const [selectedFromDate, setSelectedFromDate] = useState(new Date())
  const [selectedToDate, setSelectedToDate] = useState(new Date())
  const [selectedUser, setSelectedUser] = useState("0")

  const auditTableList = (data) => {
    if (data.length === 0) toast.error("No data found")
    const datamapped = data.map((i, idx) => {
      const {uid, username,date,action,detatils } = i
      const temp = {
        uid,
        username,
        "date":date,
        // "action":action.split("@")[0],
        action,
        "Details":detatils
      }
      return temp;
    })
    setauditTableData(datamapped)
  }
  const callingAuditLogsListData = () => {
    setauditTableData([])
    
    let model = {
      "uid": selectedUser,
      "fromdate": moment(selectedFromDate)?.format("YYYY-MM-DD"),
      "todate": moment(selectedToDate)?.format("YYYY-MM-DD")
    }
    dispatch(
      auditLogListAction({
        model,
        callback: (data) => {
          if (data?.length > 0) {
            auditTableList(data)
          }
          else toast.error("No data found")
        },
      })
    );
  }
  const settingUserList=(data)=>{

    const datamapped = data.map((i, idx) => {
      const { username, uid, } = i
      const temp = {
        "label": username,
        "value": uid,
      }
      return temp;
    })

    setAuditLogsUser(datamapped)

  }

  const callingAuditLogsUserData = () => {
    dispatch(
      auditLogUserAction({
        callback: (data) => {
          if (data?.length > 0) {
            settingUserList(data)
            return
          }
          else toast.error("No data found")
        },
      })
    );
  }

  const onSelectUser = async (e) => {
    const { value } = e?.target
    setSelectedUser(value);
  };
  

  const onSelectFromDate = async (e) => {
    setSelectedFromDate(e);
  };
  const onSelectToDate = async (e) => {
    setSelectedToDate(e)
  };

  useEffect(() => {
    callingAuditLogsUserData()
  }, [])



  return {
    auditLogsUser,
    selectedFromDate,
    selectedToDate,
    onSelectFromDate,
    onSelectToDate,
    callingAuditLogsListData, auditTableData,
    selectedUser,
    setSelectedUser,
    onSelectUser,
  }
}

export default AuditLogPresenter
