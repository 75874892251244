import React from 'react'
import ReportTable from '../../components/Table'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, InputGroup, Row, Table } from 'react-bootstrap'
import LeaveSummaryPresenter from './LeaveSummaryPresenter'
import Calendar from "../../components/Calendar"
import LoaderComp from '../../components/LoaderComp/LoaderComp'
import { ApproveSvg } from '../../assets/svgs/Svg'
import { ApprovSvg, CancelSvg, PendingSvg, RejectSvg } from '../../assets/svgs/LeavesSvg'


const Leave = () => {
  const { Breadcrumbs, leaveSummaryData,
    handleClick,
    leaveSummaryTableData,
    selectedFromDate,
    selectedToDate,
    onSelectFromDate,
    onSelectToDate,
    callingLeaveSummaryData,
  } = LeaveSummaryPresenter()

  return (
    <div>
      <LoaderComp />
      <div className="main justify-content-between">
        <div className='d-flex justify-content-between'>
          <h5 className="mt-3 mb-3">Leave Summary Report</h5>
        </div>
        <Card className="col-12"
        >
          <Row className="">
            <FormGroup className="text-start form-group col-12 col-sm-4">
              <Form.Label>From</Form.Label>
              <div className="mb-4" style={{ "marginRight": "-50px" }}>
                <Calendar
                  selectedDate={selectedFromDate}
                  onSelectDate={onSelectFromDate}
                />
              </div>
            </FormGroup>
            <FormGroup className="text-start form-group col-12 col-sm-4">
              <Form.Label>To</Form.Label>
              <div className="mb-4">

                <Calendar
                  selectedDate={selectedToDate}
                  onSelectDate={onSelectToDate}
                />
              </div>
            </FormGroup>
            <FormGroup className="text-start form-group col-12 col-sm-4 mt-3">
              <div className="mb-4 mt-3" >
                <Button type="button"
                  onClick={() => callingLeaveSummaryData()}
                >Submit</Button>
              </div>
            </FormGroup>
          </Row>
        </Card>

        {leaveSummaryTableData?.length > 0 ?
          <>
            <Row className="row-sm">
              <Col xl={3} lg={6} md={6} sm={12}>
                <Card>
                  <Card.Body className="iconfont text-start d-flex">
                    <div className="media mt-0">
                    <ApprovSvg/>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <div className="mt-0 ms-3">
                            <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                              Approved
                            </h5>
                            <p className="mb-0 tx-12 text-muted">
                              {leaveSummaryData?.approved}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6} sm={12}>
                <Card>
                  <Card.Body className="iconfont text-start">
                    <div className="media mt-0">
                      <PendingSvg/>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <div className="mt-0 ms-3">
                            <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                              Pending
                            </h5>
                            <p className="mb-0 tx-12 text-muted">
                              {leaveSummaryData?.pending}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6} sm={12}>
                <Card>
                  <Card.Body className="iconfont text-start">
                    <div className="media mt-0">
                      <CancelSvg/>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <div className="mt-0 ms-3">
                            <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                              Cancelled
                            </h5>
                            <p className="mb-0 tx-12 text-muted">
                              {leaveSummaryData?.cancelled}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6} sm={12}>
                <Card>
                  <Card.Body className="iconfont text-start">
                    <div className="media mt-0">
                      <RejectSvg/>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <div className="mt-0 ms-3">
                            <h5 className="mb-1 tx-13 font-weight-sembold text-dark">
                              Rejected
                            </h5>
                            <p className="mb-0 tx-12 text-muted">
                              {leaveSummaryData?.rejected}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <ReportTable
              title={'Leave Report'}
              fileName={"Leave Summary Report"}
              // clickableColumns={[[0, 1], handleClick]}
              // StyledColumns={[
              //   [0, 1, 7, 8, 9],
              //   [" underline", "underline"],
              // ]}
              tableData={leaveSummaryTableData}
            // selectedDate={selectedDate}
            // onSelectDate={onSelectDate}
            />
          </>
          : <></>
        }
      </div>

    </div>
  )
}

export default Leave